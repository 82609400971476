.topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 60px;
    height: 70px;
    width: 100%;
    background-color: #fff;
    filter: drop-shadow(0px 4px 20px rgba(67, 103, 176, 0.12));

    .headerRight {
        display: flex;
        gap: 70px;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 110px;
    }

    .loginForm {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        input {
            color: #4367B0;
            width: 470px;
            padding: 12px 2px 12px 40px;

            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
            border-radius: 22px;
            border: 1px solid #BFBFBF;
            background-color: #fff;
        }

        input::placeholder {
            color: #BFBFBF;
        }

        input:focus {
            outline: 1px solid #4367B0;
        }

        .inputSearch {
            position: relative;
            width: 100%;
        }

        .searchIcon {
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }

        .searchBtnContainer {
            position: absolute;
            right: 5px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .searchBtn {
                border-radius: 36px;
                background-color: #D0D9EB;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
                padding: 5px 20px;
                border: none;
            }

            .active {
                background-color: #4367B0;
            }
        }

    }

    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding-right: 20px;

        .avatarIcon {
            border-radius: 50px;
            background-color: #E9EEFB;
            // padding: 5px;
            width: 30px;
            height: 30px;
            object-fit: cover;
            border: 1px solid #4367B0;

            .number {
                color: #1B1919;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.16px;
            }
        }
    }

}