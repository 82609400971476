/** @format */

.attachmentCard {
	width: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1.3rem 1rem;
	background-color: #f4f6fb;
	border-radius: 1rem;
}
.attachmentHeading {
	font-size: 1.3rem;
	font-weight: 600;
	margin-bottom: 1rem;
}
.downloadBtn {
	display: flex;
	align-items: center;
	font-size: 1rem;
	gap: 0.3rem;
	background-color: #4367b0;
	padding: 0.5rem;
	border-radius: 0.5rem;
	outline: none;
	color: #fff;
	border: 0;
	margin-top: 1rem;
}
