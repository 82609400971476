.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.VideoApprovModal {
    .video-modal-content {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
        gap: 20px;
        // height: 30vh;


        .video-modal-header {
            border-radius: 10px 10px 0 0;
            background: var(--Primary-100, #4367B0);
            padding: 24px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .video-title {
                color: var(--White-100, #FFF);
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0.24px;
            }

            .closeBtn {
                color: #000;
                background-color: #fff;
                padding: 10px;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;


            }
        }

        .body {
            width: 90%;
            display: flex;
            flex-direction: column;
            // align-items: center;
            // justify-content: center;
            gap: 20px;
            padding: 0 20px;
            height: 100%;

            label {
                color: #000000;
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.16px;
            }

            input,
            select {
                margin-top: 5px;
                color: #4367b0;
                width: 100%;
                // width: 470px;
                padding: 8px 15px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.16px;
                border-radius: 22px;
                border: 1px solid #bfbfbf;
                background-color: #fff;
            }


            input::placeholder {
                color: #bfbfbf;
            }

            input:focus {
                outline: 1px solid #4367b0;
            }

            .topSearchBar {
                margin: 15px 0;

                .searchInputs {
                    display: flex;
                    gap: 20px;
                    align-items: center;

                    flex-wrap: wrap;
                    margin: 10px 0;

                    .inputContainer {
                        width: 23%;
                    }


                }
            }
        }

        .saveButtonWrapper {
            padding: 20px;
            display: flex;
            align-items: center;


            .approveBtn {
                border-radius: 36px;
                background: var(--Primary-100, #4367B0);
                width: 110px;
                height: 45px;
                color: var(--White-100, #FFF);
                text-align: center;
    
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.16px;
                border: none;
            }
        }




    }
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-overlay,
.modal-content {
    animation: fadeIn 0.3s ease-in-out;
}