@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.BusinessOwnerSolutionsContainer {
	@include flex(column, 0, center, center);
	padding: 8rem 0 5rem;
	h1 {
		@include fontStyle(3.5rem, 700, 4rem);
		text-align: center;
	}
	.listContainer {
		@include flex(row, 2rem, center, center);
		flex-wrap: wrap;
		margin-top: 3rem;
		width: 80%;
		.Challangelist {
			@include flex(row, 0, start, center);
			@include fontStyle(1.1rem, 500, 1.3rem);
			background-color: #edeeee;
			padding: 1.5rem;
			width: 40%;
			border-radius: 25px;
			box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px,
				rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px,
				rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
		}
	}
	.solution {
		@include fontStyle(2rem, 500, 1.4rem);
		margin: 3rem 0 1rem;
		span {
			font-weight: 700;
		}
	}
	.desc {
		@include fontStyle(1.5rem, 500, 2rem);
		width: 65%;
		text-align: center;
		margin-bottom: 3rem;
	}
	button {
		@include flex("", 0.5rem, center, center);
		@include fontStyle(1.5rem, 600, 1.125rem);
		border: none;
		border-radius: 0.75rem;
		padding: 1.25rem 2.5rem;
		background-color: #4367b0;
		color: #ffffff;
	}
}

@media (max-width: 1024px) {
	.BusinessOwnerSolutionsContainer {
		h1 {
			@include fontStyle(3rem, 700, 4rem);
		}
		.listContainer {
			width: 100%;
		}
		.solution {
			@include fontStyle(2rem, 500, 2rem);
			margin: 3rem 5rem 1rem;
			text-align: center;
		}
	}
}

@media (max-width: 768px) {
	.BusinessOwnerSolutionsContainer {
		padding: 5rem 0 3rem;
		h1 {
			@include fontStyle(1.8rem, 700, 2.2rem);
		}
		.solution {
			@include fontStyle(1.2rem, 500, 1.8rem);
			margin: 3rem 1rem 1rem;
			text-align: center;
		}
		.desc {
			@include fontStyle(1.2rem, 500, 1.8rem);
			width: 80%;
			text-align: center;
			margin-bottom: 3rem;
		}
		.listContainer {
			@include flex(column, 1.5rem, center, center);
			width: 88%;
			.Challangelist {
				width: 100%;
			}
		}
		button {
			@include fontStyle(1rem, 600, 1rem);
			height: 3rem;
			padding: 1rem 1.5rem;
		}
	}
}
