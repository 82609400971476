.natureBusiness {
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 0.28px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.buttonContainer {
					display: flex;
					gap: 5px;
				}

				.downloadBtn {
					color: #fff;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
					border: none;
					background-color: #4367b0;
					border-radius: 25px;
					padding: 0 10px;
					height: 45px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					img {
						height: 20px;
						width: 20px;
					}
				}
			}

			.attendanceListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin: 20px 0;

				.topSearchBar {
					margin: 15px 0;

					.searchInputs {
						display: flex;
						gap: 20px;
						align-items: flex-end;
						overflow: auto;
						margin: 10px 0;

						label {
							color: #000000;
							font-weight: 500;
							font-size: 16px;
							letter-spacing: 0.16px;
						}

						input,
						select {
							color: #4367b0;
							padding: 15px;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 0.16px;
							border-radius: 22px;
							border: 1px solid #bfbfbf;
							background-color: #fff;
						}

						select {
							width: 150px;
						}

						input::placeholder {
							color: #bfbfbf;
						}

						input:focus {
							outline: 1px solid #4367b0;
						}

						.searchBtnContainer {
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;

							.searchBtn {
								border-radius: 36px;
								background-color: #d0d9eb;
								color: #fff;
								text-align: center;
								font-size: 16px;
								font-weight: 500;
								letter-spacing: 0.16px;
								padding: 15px 30px;
								border: none;
							}

							.active {
								background-color: #4367b0;
							}
						}
					}
				}

				.courses {
					overflow-x: auto;
					margin: 10px 0;
					table {
						border-collapse: collapse;
						border-spacing: 0;
						width: 100%;
					}

					tr th {
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
					}

					tr td {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
					}

					th,
					td {
						text-align: left;
						padding: 8px;
					}

					tr {
						border-bottom: 1px solid #bfbfbf;
					}

					//   tr:nth-child(even){background-color: #f2f2f2}
				}
			}
			.paginationWrapper {
				position: "absolute";
				bottom: 20px;
				right: 20px;
			}
		}
	}

	.actionBtn {
		// width: 200px;
		display: flex;
		gap: 10px;
	}

	.editBtn {
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.16px;

		background-color: #209f4a;
		border-radius: 8px;
		padding: 0 10px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		border: none;
	}

	.homeworkBtn,
	.materialBtn {
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.16px;
		background-color: #4367b0;
		border-radius: 8px;
		padding: 0 10px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pagination {
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
		margin: 30px auto;

		button {
			border-radius: 50%;
			border: 1px solid #e6e6e6;
			background: #fff;
			// padding: 10px 20px;
			height: 45px;
			width: 45px;
			color: #000;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
		}

		button:disabled {
			background-color: #eee;
			color: gray;
		}

		.active {
			border-radius: 6px;
			background: #000;
			color: #fff;
		}
	}
}
