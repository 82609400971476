


.login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // background: url('../../assets/loginBanner.jpeg'), linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1));
    background-blend-mode: overlay;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


    .loginContainer {
        width: 25vw;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        z-index: 4;

        margin-left: 20%;


        .logo {
            margin: 0 auto;
        }

        .title {
            color: #BFBFBF;
            text-align: center;
            font-size: 20px !important;
            font-weight: 400;
            letter-spacing: 0.2px;
        }

        .loginForm {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            label {
                color: #000000;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.2px;
                padding-bottom: 10px;

                span {
                    color: #D80032;
                }
            }

            input {
                border-radius: 50px;
                background: #F5F7FA;
                font-size: 20px;
                font-weight: 400;
                color: #4367B0;
                letter-spacing: 0.2px;
                border: none;
                width: 100%;
                padding: 18px 60px;
            }

            input::placeholder {
                color: #BFBFBF;
            }

            input:focus {
                outline: 1px solid #4367B0;
            }

            .inputUserName,
            .inputPass {
                position: relative;
                width: 100%;
                margin-top: 10px;
            }

            .emailIcon,
            .passIcon {
                // width: 20x;
                position: absolute;
                left: 25px;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }

            .eyeIcon {
                position: absolute;
                background-color: #fff;
                border-radius: 50%;
                padding: 10px;
                position: absolute;
                right: 25px;
                top: 0;
                bottom: 0;
                margin: auto 0;
                cursor: pointer;
            }
        }

        .bottomText {
            color: #000000;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.14px;
        }

        .resetPass {
            color: #4367B0;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.16px;
        }

        .loginBtn {
            width: 100%;
            padding: 15px;
            align-items: center;
            border-radius: 36px;
            background: #4367B0;
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.2px;
            border: none;
            position: relative;

            .forwardArrow {
                position: absolute;
                background-color: #fff;
                border-radius: 50%;
                padding: 10px;
                position: absolute;
                right: 25px;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }
        }
    }


    @media only screen and (min-width: 1400px) {
        .title {

        }

        .loginBtn {

        }
      
        tr,td,th {
            font-size: 14px !important;
        }
      
        button, input, label, a {
          font-size: 14px !important;
        }
      
        
       }
      @media only screen and (max-width: 1400px) {
        .title {
           font-size: 20px !important;
        }
      
        tr,td,th {
            font-size: 12px !important;
        }
      
        button, input, label, a {
          font-size: 12px !important;
        }
      
       }
}

