.newStudentCSV {
	.modal-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.7);
		.modal-content {
			height: fit-content;
			border-radius: 5px;
			width: 32%;
			padding: 1rem 0;
			position: relative;
			.reasonTitle {
				font-size: 1.7rem;
				font-weight: 700;
				color: rgb(20, 20, 20);
				text-align: center;
				padding: 0 0.5rem;
			}
			.statusContainer {
				max-height: 10rem;
				padding: 0 2rem;
				margin: 0 0.5rem;
				overflow-y: scroll;
				.orderedList {
					li {
						color: #030303;
					}
				}
			}
			.closeBtn {
				font-size: 1.5rem;
				font-weight: 700;
				position: absolute;
				background-color: transparent;
				border: none;
				padding: 0.7rem 1rem;
				right: 0;
				top: 0;
				border-radius: 50%;
			}
		}
	}
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 28px;
				font-weight: 600;
				letter-spacing: 0.28px;
			}

			.batchStudentListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin: 20px 0;

				.studentDetails {
					border-radius: 15px;
					border: 1px solid var(--Secondary-25, #bfbfbf);

					table {
						border-collapse: collapse;
						border-spacing: 0;
						// width: 100em;
						width: 100%;
					}

					thead {
						tr th {
							color: #000000;
							padding: 16px 12px;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 0.2px;
							white-space: nowrap;
							text-align: left;
							border: none !important;
						}
					}

					tbody {
						background-color: #f4f6fb;
						tr td {
							color: #000;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 0.16px;
							white-space: nowrap;
							padding: 20px 12px;
							text-align: left;
						}
					}
					tr {
						border-bottom: 1px solid var(--Secondary-25, #bfbfbf);
					}

					.studentTable {
						width: 100%;
					}
				}

				.uploadContent {
					.title {
						color: var(--Secondary-100, #000);
						font-size: 20px;
						font-weight: 400;
						letter-spacing: 0.2px;
					}
				}

				.row {
					display: flex;
					gap: 10px;

					.bullet {
						color: var(--Secondary-100, #000);
						text-align: center;
						font-size: 20px;
						font-weight: 600;
						letter-spacing: 0.2px;
					}

					.firstTitle {
						color: var(--Secondary-100, #000);
						font-size: 20px;
						font-weight: 400;
						letter-spacing: 0.2px;
					}
				}

				.first {
					align-items: center;
				}

				.second {
					h6 {
						color: var(--Secondary-100, #000);
						font-size: 20px;
						font-weight: 600;
						letter-spacing: 0.2px;
					}

					p {
						color: var(--Secondary-100, #000);
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
					}
				}

				.fourth {
					.uploadArea {
						width: 350px;
						border-radius: 8px 8px 8px 8px;
						border: 1px solid #bfbfbf;
						background-color: #fff;
						display: flex;
						flex-direction: column;
						gap: 10px;
						margin-top: 20px;

						.titleHomework {
							border-radius: 8px;
							background: var(--Secondary-100, #000);
							padding: 10px;

							color: var(--White-100, #fff);
							font-size: 16px;
							font-weight: 500;
							letter-spacing: 0.16px;
						}

						.uploadInfo {
							color: var(--Secondary-100, #000);
							text-align: center;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0.12px;
						}

						.uploadHomeworkBtn {
							border-radius: 8px;
							background: var(--Primary-100, #4367b0);
							padding: 8px 12px;
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 4px;

							color: var(--White-100, #fff);
							font-size: 16px;
							font-weight: 500;
							letter-spacing: 0.16px;
							border: none;
							width: 130px;
							margin: 0 auto;
						}

						.uploadContainer {
							padding: 15px;
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: column;
							gap: 15px;
						}

						.fileNames {
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							gap: 10px;

							.fileName {
								color: var(--Primary-100, #4367b0);
								text-align: center;
								font-size: 16px;
								font-weight: 500;
								letter-spacing: 0.16px;
								text-decoration-line: underline;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 20px;

								.removeBtn {
									color: var(--Error-100, #d80032);
									display: flex;
									align-items: center;
									justify-content: center;
									gap: 10px;
									text-decoration: none;
									cursor: pointer;
								}
							}
						}
					}
				}
			}

			.downloadBtn {
				a {
					color: var(--White-100, #fff);
					text-align: center;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0.16px;
					border-radius: 6px;
					background: var(--Primary-100, #4367b0);
					padding: 8px 16px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;
					border: none;
				}
			}
			.proceedBtn {
				width: 260px;
				padding: 15px;
				align-items: center;
				border-radius: 36px;
				background: #4367b0;
				color: #fff;
				text-align: center;
				font-size: 20px;
				font-weight: 500;
				letter-spacing: 0.2px;
				border: none;
				justify-content: center;
				display: flex;
				gap: 10px;
			}
		}
	}
}
