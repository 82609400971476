.crmBatchList {

    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .batchListContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 20px;


                .topSearchBar {

                    .searchInputs {
                        display: flex;
                        gap: 20px;
                        align-items: flex-end;
                        overflow: auto;

                        label {
                            color: #000000;
                            font-weight: 500;
                            font-size: 16px;
                            letter-spacing: 0.16px;
                        }

                        input,
                        select {
                            color: #4367B0;
                            // width: 470px;
                            padding: 15px;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                            border-radius: 22px;
                            border: 1px solid #BFBFBF;
                            background-color: #fff;
                        }

                        select {
                            width: 150px;
                        }

                        input::placeholder {
                            color: #BFBFBF;
                        }

                        input:focus {
                            outline: 1px solid #4367B0;
                        }

                        .inputContainer {
                            // width: 20%;
                        }

                        .searchBtnContainer {
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;

                            .searchBtn {
                                border-radius: 36px;
                                background-color: #D0D9EB;
                                color: #FFF;
                                text-align: center;
                                font-size: 16px;
                                font-weight: 500;
                                letter-spacing: 0.16px;
                                padding: 15px 30px;
                                border: none;
                            }

                            .active {
                                background-color: #4367B0;
                            }
                        }
                    }

                }

                .courses {
                    table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        width: 100%;
                    }

                    tr th {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;
                    }

                    tr td {
                        color: #000;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                    }

                    th,
                    td {
                        text-align: left;
                        padding: 8px;

                    }

                    tr {
                        border-bottom: 1px solid #BFBFBF;

                    }

                    //   tr:nth-child(even){background-color: #f2f2f2}
                }
            }
        }
    }

    .actionBtn {
        // width: 200px;
        display: flex;
        gap: 10px;
    }

    .editBtn {
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;

        background-color: #4367B0;
        border-radius: 8px;
        padding: 0 10px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .homeworkBtn,
    .materialBtn {
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;
        background-color: #4367B0;
        border-radius: 8px;
        padding: 0 10px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pagination {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin: 30px auto;

        button {
            border-radius: 50%;
            border: 1px solid #E6E6E6;
            background: #fff;
            // padding: 10px 20px;
            height: 45px;
            width: 45px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;

        }
        button:disabled {
            background-color: #eee;
            color: gray;
        }

        .active {
            border-radius: 6px;
            background: #000;
            color: #fff;
        }

    }
}