 .CRMWrapper {
     display: flex;
     flex-direction: column;
     gap: 10px;

     .crmNameWrapper {
         display: flex;
         align-items: center;
         gap: 10px;

         .crmIconWrapper {
             display: flex;
             align-items: center;
         }
     }
 }

 .buttonWrapper {
     display: flex;
     gap: 5px;
     align-items: center;
     padding: 5px;
     border: none;
     background-color: #4367B0;
     color: #fff;
     border-radius: 5px;
     margin-bottom: 10px;

     .buttonIconWrapper {
         display: flex;
         align-items: center;
         justify-content: center;
     }
    }

     
 .buttonWrapperNonclickable {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 5px;
    border: none;
    background: grey;
    color: #fff;
    border-radius: 5px;
    

    .buttonIconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
 