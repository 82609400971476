@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.TestimonialContainer {
	@include flex(column, 1rem, center, center);
	padding: 4% 8rem;
	// background-color: #414346;
	letter-spacing: 0.11rem;
	label {
		@include fontStyle(3rem, 600, 3.6rem);
		background-color: #31353f;
		border-radius: 1.5rem;
		letter-spacing: 1px;
		padding: 0.25rem 1rem 0.125rem;
		color: #ffffff;
	}

	.videosContainer {
		@include flex(row, 3.2rem, start, center);
		flex-wrap: wrap;
		min-height: 21.75rem;
		width: 100%;
		.video {
			@include flex(column, "", center, center);
			margin-top: 2rem;
			height: 16.5rem;
			width: 30%;
			border-radius: 5px;
			// background-color: #ffffff;
			// background-color: #31353f;
			background-color: #000000;
			padding-bottom: 1rem;
			div {
				height: 100%;
				width: 100%;
				iframe {
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
					border: none;
					height: 100%;
					width: 100%;
				}
			}
			.vTitle {
				@include fontStyle(1.5rem, 700, 1.625rem);
				color: #ffffff;
				margin-top: 1rem;
			}
			p {
				@include fontStyle(0.95rem, 500, 0.95rem);
				color: #ffffff;
				margin: 0;
			}
		}
	}
}
@media (max-width: 1300px) {
	.TestimonialContainer {
		padding: 4% 4rem;
		.videosContainer {
			@include flex(row, 3.2rem, center, center);
		}
	}
}
@media (max-width: 1160px) {
	.TestimonialContainer {
		padding: 4% 2rem;
	}
}
@media (max-width: 1024px) {
	.TestimonialContainer {
		padding: 8% 2rem 10%;
		.videosContainer {
			@include flex(row, 5.2rem, center, center);
			margin-top: 4rem;
			.video {
				@include flex(column, "", center, center);
				margin-top: 0;
				height: 16.5rem;
				width: 40%;
				p {
					text-align: center;
					padding: 0 0.5rem;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.TestimonialContainer {
		padding: 2rem 2rem 5rem;
		label {
			@include fontStyle(1.3rem, 600, 2.4rem);
			text-align: center;
		}
		.videosContainer {
			@include flex(column, 3.2rem, "", "");
			min-height: 21.75rem;
			width: 100%;
			margin-top: 2rem;
			.video {
				@include flex(column, "", center, center);
				height: fit-content;
				width: 100%;
				div {
					width: 100%;
					height: 100%;
					iframe {
						border: none;
						height: 100%;
						width: 100%;
					}
				}
				.vTitle {
					@include fontStyle(1.0625rem, 700, 1.59375rem);
					text-align: center;
				}
				p {
					@include fontStyle(0.90625rem, 500, 0.90625rem);
					text-align: center;
				}
			}
		}
	}
}
