.assignCRM {
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 28px;
				font-weight: 600;
				letter-spacing: 0.28px;
			}

			.batchStudentListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin: 20px 0;

				.crmListContainer {
					.topInput {
						display: flex;
						gap: 20px;
					}
				}

				.searchBtnContainer {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;

					.searchBtn {
						border-radius: 36px;
						background-color: #4367b0;
						color: #fff;
						text-align: center;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						padding: 12px 28px;
						border: none;
					}

					.resetBtn {
						background-color: #fff;
						color: #4367b0;
						border: 1px solid #4367b0;
					}

					.active {
						background-color: #4367b0;
					}
				}

				label {
					color: #000000;
					font-weight: 500;
					font-size: 16px;
					letter-spacing: 0.16px;
				}

				input,
				select {
					margin-top: 5px;
					color: #4367b0;
					// width: 470px;
					padding: 8px 15px;
					font-size: 16px;
					font-weight: 400;
					letter-spacing: 0.16px;
					border-radius: 22px;
					border: 1px solid #bfbfbf;
					background-color: #fff;
				}

				select {
					width: 150px;
				}

				input::placeholder {
					color: #bfbfbf;
				}

				input:focus {
					outline: 1px solid #4367b0;
				}

				.topSearchBar {
					margin: 15px 0;

					.searchInputs {
						display: flex;
						gap: 20px;
						align-items: center;

						flex-wrap: wrap;
						margin: 10px 0;

						.inputContainer {
							width: 23%;
						}
					}
				}

				.courses {
					overflow-x: auto;
					margin: 10px 0;
					table {
						border-collapse: collapse;
						border-spacing: 0;
						width: 100%;
					}

					tr th {
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						white-space: nowrap;
					}

					tr td {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
						white-space: nowrap;
					}

					th,
					td {
						text-align: left;
						padding: 8px;
					}

					tr {
						border-bottom: 1px solid #bfbfbf;
					}

					.selected {
						background-color: #edf0f8;
					}

					.btnRow {
						display: flex;
						gap: 5px;
						justify-content: center;
						align-items: center;

						a {
							padding: 5px 10px;
						}
					}

					//   tr:nth-child(even){background-color: #f2f2f2}
				}
			}
			.paginationWrapper {
				position: "absolute";
				bottom: 20px;
				right: 20px;
			}
		}
	}

	.actionBtn {
		// width: 200px;
		display: flex;
		gap: 10px;
	}

	.pagination {
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
		margin: 30px auto;

		button {
			border-radius: 50%;
			border: 1px solid #e6e6e6;
			background: #fff;
			height: 45px;
			width: 45px;
			color: #000;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
		}
		button:disabled {
			background-color: #eee;
			color: gray;
		}

		.active {
			border-radius: 6px;
			background: #000;
			color: #fff;
		}

		select {
			border: 1px #f5f5f5;
			border-radius: 10px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			padding: 10px;
		}
	}
}
