.materials {
    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .materialContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 40px;

                .courseDetailForm {
                    display: flex;
                    gap: 20px;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .formInput {
                        width: 40%;
                    }

                    label {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;

                        span {
                            color: #D80032;
                        }
                    }

                    input {
                        color: #4367B0;
                        width: 100%;
                        padding: 13px 24px;

                        border: 1px solid #BFBFBF;
                        border-radius: 36px;
                        background: #FFF;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                        margin-top: 5px;
                    }

                    input::placeholder {
                        color: #BFBFBF;
                    }

                    input:focus {
                        outline: 1px solid #4367B0;
                    }

                }



                .uploadContainer {
                    display: flex;
                    gap: 50px;

                    .title {
                        color: var(--Secondary-100, #000);
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0.24px;
                        margin-bottom: 10px;
                    }

                    .maxSize {
                        color: var(--Secondary-100, #000);
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0.12px;
                    }

                    .uploadBox {
                        width: 350px;
                        border-radius: 12px;
                        border: 1px dashed #BFBFBF;
                        border-radius: 8px 8px 8px 8px;
                        background-color: #FFF;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        justify-content: center;
                        align-items: center;
                        padding: 15px;
                        height: 300px;

                        .top {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            gap: 10px;
                        }

                        .docIcon {
                            width: 65px;
                            height: 65px;
                        }

                        .uploadInfo {
                            color: var(--Secondary-100, #000);
                            text-align: center;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.12px;
                        }

                        .uploadHomeworkBtn {
                            border-radius: 8px;
                            background: var(--Primary-100, #4367B0);
                            padding: 8px 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;

                            color: var(--White-100, #FFF);
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.16px;
                            border: none;
                            width: 130px;
                            margin: 0 auto;
                        }

                        .uploadContainer {
                            padding: 15px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            gap: 15px;
                        }

                        .fileNames {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            gap: 10px;

                            .fileName {
                                color: var(--Primary-100, #4367B0);
                                text-align: center;
                                font-size: 16px;
                                font-weight: 500;
                                letter-spacing: 0.16px;
                                text-decoration-line: underline;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 20px;

                                .removeBtn {
                                    color: var(--Error-100, #D80032);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 10px;
                                    text-decoration: none;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .youtubeContainer {
                    .title {
                        color: var(--Secondary-100, #000);
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0.24px;
                        margin-bottom: 10px;
                    }

                    input {
                        border-radius: 8px;
                        border: 1px solid var(--Secondary-25, #BFBFBF);
                        background: var(--White-100, #FFF);
                        width: 60%;
                        height: 44px;
                        padding: 10px;
                        margin-bottom: 10px;
                    }

                    p {

                        color: var(--Primary-100, #4367B0);
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0.16px;
                        cursor: pointer;
                    }
                }

                .navigationBtns {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 30px;

                    .prevBtn {
                        border-radius: 52px;
                        border: 1px solid var(--Primary-100, #4367B0);
                        background: var(--White-100, #FFF);
                        color: var(--Primary-100, #4367B0);
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        width: 260px;
                        height: 56px;
                    }

                    .saveBtn {
                        border-radius: 52px;
                        background: var(--Primary-100, #4367B0);
                        color: var(--White-100, #FFF);
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        border: none;
                        width: 260px;
                        height: 56px;
                    }
                }

            }
        }
    }

    .topNavigation {
        display: flex;
        border-radius: 10px;

        .item {
            background-color: #A1B3D8;
            color: var(--White-100, #FFF);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            padding: 20px;
            width: 160px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .active {
            background-color: #4367B0;
        }
    }
}