.paymentDetail {
    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .pageTopDetails {
                display: flex;
                justify-content: space-between;

                .leftDetails, .rightDetails {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }

            .topDetails {
                gap: 20px !important;

                .topTitle {
                    color: var(--Secondary-100, #000);
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                }

                .paymentInfo {
                    border-radius: 15px;

                    table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        // width: 100em;
                        width: 100%;
                    }

                    thead {
                        background-color: #F4F6FB;
                        border-bottom: 1px solid var(--Secondary-25, #BFBFBF);

                        tr th {
                            color: #000;
                            padding: 16px 12px;
                            font-size: 18px;
                            font-weight: 600;
                            letter-spacing: 0.2px;
                            white-space: nowrap;
                            text-align: left;
                            
                        }

                    }

                    tbody {
                        tr td {
                            color: #000;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                            white-space: nowrap;
                            padding: 20px 12px;
                            text-align: left;


                        }
                    }

                    .totalDetails {
                        background-color: #F4F6FB;
                        border: none !important;

                        td {
                            font-weight: 600;
                        }
                    }


                    tr {
                        border-bottom: 1px solid #bfbfbf;
                    }


                }

            }

            .bottomInfo {
                gap: 20px !important;

                .bottomInfoTable {
                    table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        width: 100%;
                    }

                    thead {

                        tr th {
                            color: #000000;
                            padding: 16px 12px;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.2px;
                            white-space: nowrap;
                            text-align: left;
                        }

                    }

                    tbody {
                        tr td {
                            color: #000;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                            white-space: nowrap;
                            padding: 20px 12px;
                            text-align: left;


                        }
                    }

                    tr {
                        border-bottom: 1px solid #bfbfbf;
                    }
                }

                select {
                    border-radius: 52px;
                    border: 1px solid var(--Primary-100, #4367B0);
                    background: var(--White-100, #FFF);
                    color: var(--Primary-100, #4367B0);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16px;
                    padding: 12px 20px;
                }
                select:focus{
                    outline: none;
                }
            }



            .installmentInfo {
                display: flex;
                gap: 20px;
                margin-top: 20px;

                p {
                    color: var(--Secondary-100, #000);
                    text-align: center;
                    font-size: 16px;
                    letter-spacing: 0.16px;
                }
            }

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .paymentDetailsContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 40px;

                .courseDetailForm {
                    display: flex;
                    gap: 20px;
                    width: 100%;
                    justify-content: space-between;


                   

                }

                .midForm {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .topFormFields {

                        width: 50%;
                        display: flex;
                        gap: 20px;
                        flex-wrap: wrap;
                        .formInput {
                            width: 48%;
                        }
                    }
                    .textboxWrapper {
                        width: 50%;
                        display: flex;
                        gap: 20px;

                        .formInput {
                            width: 48%;
                        }
                    }

                    label {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;

                        span {
                            color: #D80032;
                        }
                    }

                    input,
                    select,
                    textarea {
                        color: #000;
                        width: 100%;
                        padding: 13px 24px;

                        border: 1px solid #BFBFBF;
                        border-radius: 36px;
                        background: #FFF;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                        margin-top: 5px;
                    }

                    input::placeholder {
                        color: #BFBFBF;
                    }

                    input:focus {
                        outline: 1px solid #4367B0;
                    }
                    input:disabled {
                        background: var(--Secondary-4, #F5F5F5);
                    }
                }

                .footerNavBtns {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;

                    .backBtn {
                        width: 260px;
                        padding: 15px;
                        align-items: center;
                        border-radius: 36px;
                        background: #fff;
                        border: 1px solid #4367B0;
                        color: #4367B0;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        justify-content: center;
                        display: flex;
                        gap: 10px;
                    }

                    .nextBtn {
                        width: 260px;
                        padding: 15px;
                        align-items: center;
                        border-radius: 36px;
                        background: #4367B0;
                        color: #FFF;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        border: none;
                        justify-content: center;
                        display: flex;
                        gap: 10px;

                        // .forwardArrow {
                        //     position: absolute;
                        //     background-color: #fff;
                        //     border-radius: 50%;
                        //     padding: 10px;
                        //     position: absolute;
                        //     right: 25px;
                        //     top: 0;
                        //     bottom: 0;
                        //     margin: auto 0;
                        // }
                    }
                }
            }
        }

        .uploadAreaWrapper {
            width: 100%;
        }


        .uploadArea {
            width: 60%;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #BFBFBF;
            background-color: #FFF;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .titleHomework {
                border-radius: 8px;
                background: var(--Secondary-100, #000);
                padding: 10px;

                color: var(--White-100, #FFF);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
            }

            .uploadInfo {
                color: var(--Secondary-100, #000);
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }

            .uploadHomeworkBtn {
                border-radius: 8px;
                background: var(--Primary-100, #4367B0);
                padding: 8px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                color: var(--White-100, #FFF);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
                border: none;
                width: 130px;
                margin: 0 auto;
            }

            .uploadContainer {
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 15px;
            }

            .fileNames {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 10px;

                .fileName {
                    color: var(--Primary-100, #4367B0);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16px;
                    text-decoration-line: underline;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    .removeBtn {
                        color: var(--Error-100, #D80032);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .topNavigation {
        display: flex;
        border-radius: 10px;

        .item {
            background-color: #A1B3D8;
            color: var(--White-100, #FFF);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            padding: 20px;
            width: 160px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .active {
            background-color: #4367B0;
        }
    }
}