.createCalendar {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .createCalendarContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 10px;

        .createCalendarForm {
          display: flex;
          gap: 30px;
          width: 50%;
          flex-direction: column;
          justify-content: space-between;

          .formInput {
            width: 100%;
          }

          label {
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;

            span {
              color: #d80032;
            }
          }

          input,
          select,
          textarea {
            color: #bfbfbf;
            width: 100%;
            padding: 13px 24px;

            border: 1px solid #bfbfbf;
            border-radius: 36px;
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
            margin-top: 5px;
          }

          input::placeholder,
          textarea::placeholder {
            color: #bfbfbf;
          }

          input:focus {
            outline: 1px solid #4367b0;
          }

          .saveBtn {
            background-color: #4367b0;
            color: white;
            padding: 14px;
            border: none;
            border-radius: 5px;
          }

          .uploadHomeworkBtn {
            border-radius: 8px;
            background: var(--Primary-100, #4367b0);
            padding: 8px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            color: white;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            border: none;
            width: 130px;
            margin: 0 auto;
          }

          .uploadContainer {
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 15px;
            width: 100%;
            border-radius: 14px;
            border: 1px solid #e6e6e6;

            .fileHeading {
                font-weight: 400;
                font-size: 18px;
            }
          }

          .fileNames {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            .fileName {
              color: var(--Primary-100, #4367b0);
              text-align: center;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.16px;
              text-decoration-line: underline;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 20px;

              .removeBtn {
                color: var(--Error-100, #d80032);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
