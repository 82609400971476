@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.WhatsAppPicsContainer {
	// @include flex(column, 1.5rem, center, center);
	padding: 5rem 0;
	position: relative;

	.maititle {
		@include fontStyle(3rem, 700, 2rem);
		color: #3d62ad;
		margin-top: 3rem;
		text-align: center;
		width: 100%;
	}
	.subtitle {
		@include fontStyle(2rem, 700, 1.5rem);
		margin-top: 2rem;
		text-align: center;
		width: 100%;
	}
	.wpDesc {
		@include fontStyle(1.5rem, 500, 1.5rem);
		margin-top: 2rem;
		text-align: center;
		width: 100%;
	}
	.slider-container {
		width: 70%;
		margin: auto;
		margin-top: 3rem;
		div.items {
			height: 33rem;
			margin-right: 2rem;
			img {
				height: 100%;
				width: 100%;
			}
		}
	}
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.7);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal-content {
		background: white;
		padding-top: 2rem;
		width: fit-content;
		height: fit-content;
		border-radius: 8px;
		position: relative;
	}

	.modal-content img {
		max-width: 100%;
		max-height: 90vh;
	}

	.close-button {
		@include fontStyle(1.5rem, 700, "");
		position: absolute;
		top: 0;
		right: 0;
		background: transparent;
		border: none;
		padding: 5px 10px;
		cursor: pointer;
	}
}

@media (max-width: 1024px) {
	.WhatsAppPicsContainer {
		.slider-container {
			div.items {
				height: 22rem;
				margin-right: 1rem;
			}
		}
		.modal-content img {
			max-height: 80vh;
		}
	}
}
@media (max-width: 768px) {
	.WhatsAppPicsContainer {
		padding: 0 0 5rem;
		.maititle {
			@include fontStyle(2rem, 700, 1.5rem);
		}
		.subtitle {
			@include fontStyle(1.66rem, 700, 1rem);
			margin-top: 1.5rem;
		}
		.wpDesc {
			@include fontStyle(1.1rem, 500, 1.3rem);
			margin-top: 1.5rem;
			padding: 0 2rem;
		}
		.slider-container {
			width: 80%;
			div.items {
				height: 14rem;
				margin-right: 0.7rem;
			}
		}
	}
}
