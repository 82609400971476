@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.HeroContainer {
	@include flex(row, "", space-between, center);
	height: 100vh;
	padding: 0 8rem;

	.descContainer {
		@include flex(column, 1rem, start, start);
		position: relative;
		label {
			@include fontStyle(1rem, 600, 1.5rem);
			background-color: #ebf1ff;
			border-radius: 1.5rem;
			padding: 0.25rem 1rem 0.125rem;
			color: #4367b0;
		}
		h1.title1,
		h1.title2 {
			@include fontStyle(4.75rem, 600, 5.225rem);
		}
		.title2 {
			margin-top: -1rem;
			color: #4367b0;
		}
		p {
			@include fontStyle(1.1875rem, 400, 1.85625rem);
			color: #868c98;
			width: 90%;
			letter-spacing: 0.0625rem;
			span {
				@include fontStyle(1.1875rem, 700, 1.85625rem);
				color: #868c98;
			}
		}
		.heroSectionBtnsContainer {
			@include flex(row, 0.8rem, center, center);
			.getStartedBtn {
				@include flex(column, 0, center, center);
				border: none;
				border-radius: 0.75rem;
				width: 8rem;
				height: 4rem;
				background-color: #4367b0;
				box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
					rgba(0, 0, 0, 0.23) 0px 3px 6px;
				overflow: hidden;
				.state {
					@include flex(row, "", center, center);
					@include fontStyle(1.2rem, 700, 1.125rem);
					height: 40%;
					padding: 0.8rem 0;
					color: #ffffff;
				}
				.date {
					@include flex(row, "", center, center);
					@include fontStyle("", 700, 1.5rem);
					letter-spacing: 0.7px;
					width: 100%;
					height: 60%;
					text-align: center;
					background-color: #ffffff;
					color: #000000;
				}
			}
		}
		.timings {
			@include fontStyle(1.3rem, 700, 1.5rem);
		}
		.CursiveArrow {
			position: absolute;
			top: 95%;
			right: 15%;
		}
	}
	.HeroImgContiner {
		position: relative;
		div {
			position: absolute;
			right: 0;
			top: -2.5rem;
			height: 22.5rem;
			width: 22.5rem;
			background-color: #ebf1ff;
			border-radius: 1rem;
			z-index: -1;
		}
		.HeroImg {
			height: 500px;
			width: 600px;
			border-radius: 0.75rem;
			margin-right: 2.5rem;
		}
		.subHeroImg {
			height: 230px;
			width: 244px;
			position: absolute;
			bottom: 0;
			top: 65%;
			right: 9.5%;
		}
	}
}
@media (max-width: 1350px) {
	.HeroContainer {
		padding: 0 6rem;
		.CursiveArrow {
			height: 2.625rem;
			width: 8.4375rem;
			top: 70%;
			transform: rotate(127deg);
			right: -2%;
		}
	}
}
@media (max-width: 1250px) {
	.HeroContainer {
		padding: 0 4rem;
	}
}
@media (max-width: 1160px) {
	.HeroContainer {
		padding: 0 4rem 6rem;
	}
}
@media (max-width: 1024px) {
	.HeroContainer {
		width: 100%;
		padding: 0 2rem 3.5rem;
		height: fit-content;
		margin-top: 4rem;
		flex-direction: row;
		.descContainer {
			width: 50%;
			label {
				padding: 0.125rem 1rem;
				font-size: 0.9rem;
			}
			h1.title1,
			h1.title2 {
				@include fontStyle(2.8125rem, 600, 3.09375rem);
				letter-spacing: 0.0625rem;
			}
			p {
				@include fontStyle(1.125rem, "", 1.625rem);
				width: 100%;
				span {
					@include fontStyle(1.125rem, "", 1.625rem);
				}
			}
			.heroSectionBtnsContainer {
				.getStartedBtn {
					height: 3.8rem;
				}
			}

			.CursiveArrow {
				height: 2.625rem;
				width: 8.4375rem;
				top: 75%;
				transform: rotate(127deg);
				right: -5%;
			}
		}
		.HeroImgContiner {
			div {
				height: 11.25rem;
				width: 11.25rem;
				top: -1rem;
				right: -1rem;
			}
			.HeroImg {
				width: 22.775rem;
				height: 19.64375rem;
				border-radius: 0.75rem;
				margin-right: 0rem;
			}
			.subHeroImg {
				width: 9.075rem;
				height: 8.55rem;
				top: 60%;
				right: 3%;
			}
		}
	}
}
@media (max-width: 768px) {
	.HeroContainer {
		padding: 0 1.25rem 3.5rem;
		flex-direction: column;
		overflow: hidden;

		.descContainer {
			width: 100%;
			.heroSectionBtnsContainer {
				.getStartedBtn {
					width: 7rem;
					height: 3.8rem;
					.state {
						@include fontStyle(1rem, 700, 1.125rem);
						height: 40%;
						padding: 0.8rem 0;
					}
					.date {
						@include fontStyle(0.8rem, 700, 1.5rem);
					}
				}
			}
			.timings {
				@include fontStyle(1.1rem, 700, 1.5rem);
				margin-bottom: 2rem;
			}
			.CursiveArrow {
				height: 2.525rem;
				width: 8rem;
				right: -10%;
			}
		}
		.HeroImgContiner {
			.HeroImg {
				width: 18.775rem;
				height: 15.64375rem;
			}
		}
	}
}
