.newStudentCSV {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .batchStudentListContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px 0;


        .studentDetails {
          border-radius: 15px;
          border: 1px solid var(--Secondary-25, #BFBFBF);


          table {
            border-collapse: collapse;
            border-spacing: 0;
            // width: 100em;
            width: 100%;
          }

          thead {

            tr th {
              color: #000000;
              padding: 16px 12px;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0.2px;
              white-space: nowrap;
              text-align: left;
              border: none !important;
            }

          }

          tbody {
            background-color: #F4F6FB;
            tr td {
              color: #000;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0.16px;
              white-space: nowrap;
              padding: 20px 12px;
              text-align: left;


            }

          }
          tr {
            border-bottom: 1px solid var(--Secondary-25, #BFBFBF);

          }

          .studentTable {
            width: 100%;

          }
        }

        .uploadContent {
          .title {
            color: var(--Secondary-100, #000);
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }


        .row {
          display: flex;
          gap: 10px;

          .bullet {
            color: var(--Secondary-100, #000);
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.2px;
          }

          .firstTitle {
            color: var(--Secondary-100, #000);
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }

        .first {
          align-items: center;
        }

        .second {
          h6 {
            color: var(--Secondary-100, #000);
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.2px;
          }

          p {
            color: var(--Secondary-100, #000);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
          }
        }

        .fourth {
          .uploadArea {
            width: 350px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #BFBFBF;
            background-color: #FFF;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;

            .titleHomework {
                border-radius: 8px;
                background: var(--Secondary-100, #000);
                padding: 10px;

                color: var(--White-100, #FFF);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
            }

            .uploadInfo {
                color: var(--Secondary-100, #000);
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }

            .uploadHomeworkBtn {
                border-radius: 8px;
                background: var(--Primary-100, #4367B0);
                padding: 8px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                color: var(--White-100, #FFF);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
                border: none;
                width: 130px;
                margin: 0 auto;
            }

            .uploadContainer {
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 15px;
            }

            .fileNames {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 10px;

                .fileName {
                    color: var(--Primary-100, #4367B0);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16px;
                    text-decoration-line: underline;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    .removeBtn {
                        color: var(--Error-100, #D80032);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
          }
        }
      }

      .downloadBtn {
        a {
          color: var(--White-100, #FFF);
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.16px;
          border-radius: 6px;
          background: var(--Primary-100, #4367B0);
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border: none;
        }
      }
      .proceedBtn {
        width: 260px;
        padding: 15px;
        align-items: center;
        border-radius: 36px;
        background: #4367B0;
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.2px;
        border: none;
        justify-content: center;
        display: flex;
        gap: 10px;
      }
    }
  }

}