.profile {
    .dashboardBody {
        display: flex;

        .dashboardContent {
             width: 100%;

            .blueHeader {
                height: 210px;
                background-image: url('../../assets/blueBg.jpeg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                padding: 20px;


                .title {
                    color: #FFF;
                    font-size: 28px;
                    font-weight: 600;
                    letter-spacing: 0.28px;
                }
            }

            .contentBody {
                display: flex;
                gap: 20px;
                padding: 0 100px;
                align-items: flex-start;
                margin-top: -100px;

                .profileUser {
                    border-radius: 12px;
                    border: 1px solid var(--Secondary-25, #BFBFBF);
                    background: var(--White-100, #FFF);
                    width: 30%;
                    overflow: hidden;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 30px;

                    .details {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        gap: 5px;

                        .name {
                            color: var(--Secondary-100, #000);
                            text-align: center;
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 0.2px;
                        }

                        .email {
                            color: var(--Secondary-100, #000);
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                        }

                        .status {
                            border-radius: 52px;
                            border: 1px solid var(--Success-100, #209F4A);
                            background: var(--White-100, #FFF);
                            color: var(--Success-100, #209F4A);
                            text-align: center;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.14px;
                            padding: 5px 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;

                            span {
                                width: 5px;
                                height: 5px;
                                background-color: #209F4A;
                                display: inline-block;
                                border-radius: 100%;
                            }
                        }
                    }

                    hr {
                        width: 100%;
                        border: 1px solid #BFBFBF;
                    }

                    .userImg {
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        background-color: #E6E6E6;
                        padding: 20px;
                    }

                    .userImgUpdated {
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                    }

                    .uploadIcon {}

                    .uploadBtn {
                        border-radius: 33px;
                        border: 1px solid var(--Primary-100, #4367B0);
                        padding: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;

                        color: var(--Primary-100, #4367B0);
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;
                        background-color: #fff;
                    }

            

                    .removeBtn {
                        border-radius: 33px;
                        border: 1px solid var(--Primary-100, #D80032);
                        padding: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;

                        color: var(--Primary-100, #D80032);
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;
                        background-color: #fff;
                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .userForm {
                    width: 80%;
                    display: flex;
                    gap: 30px;
                    flex-direction: column;
                    border-radius: 12px;
                    border: 1px solid var(--Secondary-25, #BFBFBF);
                    background: #FFF;
                    padding: 40px;

                    .title {
                        color: #000;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0.28px;
                        margin-bottom: 10px;
                    }

                    .formFields {
                        justify-content: space-between;
                        display: flex;
                        width: 80%;
                        flex-wrap: wrap;
                        gap: 20px;

                        .inputPass {
                            position: relative;
     
                            .eyeIcon {
                                position: absolute;
                                background-color: #fff;
                                border-radius: 50%;
                                padding: 10px;
                                position: absolute;
                                right: 15px;
                                top: 0;
                                bottom: 0;
                                margin: auto 0;
                                cursor: pointer;
                                padding-bottom: 5px;
                                width: 40px;
                            }
                
    
                        }

                        .formInput {
                            width: 48%;
                        }

                        label {
                            color: #000000;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.16px;

                            span {
                                color: #D80032;
                            }
                        }

                        input,
                        select {
                            color: #BFBFBF;
                            width: 100%;
                            padding: 13px 24px;

                            border: 1px solid #BFBFBF;
                            border-radius: 36px;
                            background: #FFF;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                            margin-top: 5px;
                        }

                        input::placeholder {
                            color: #BFBFBF;
                        }

                        input:focus {
                            outline: 1px solid #4367B0;
                        }

                    }

                    .status {
                        p {
                            font-weight: 600;
                            font-size: 24px;

                        }

                        .radioBtns {
                            display: flex;
                            gap: 20px;

                            label {
                                display: flex;
                                gap: 10px;
                                border-radius: 8px;
                                border: 1px solid var(--Primary-50, #A1B3D8);
                                background: var(--White-100, #FFF);
                                padding: 5px;
                            }
                        }
                    }


                    .roles {
                        p {
                            font-weight: 600;
                            font-size: 24px;

                        }
                        .checkboxBtn {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                          
    
                            label {
                                display: flex;
                                gap: 10px;
                                border-radius: 8px;
                                border: 1px solid var(--Primary-50, #A1B3D8);
                                background: var(--White-100, #FFF);
                                padding: 5px;
                                justify-content: center;
                                align-items: center;

                            }

                            input {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }

                   

                    .nextBtn {
                        width: 30%;
                        margin: auto;
                        padding: 15px;
                        align-items: center;
                        border-radius: 36px;
                        background: #4367B0;
                        color: #FFF;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        border: none;
                        position: relative;

                        .forwardArrow {
                            position: absolute;
                            background-color: #fff;
                            border-radius: 50%;
                            padding: 10px;
                            position: absolute;
                            right: 25px;
                            top: 0;
                            bottom: 0;
                            margin: auto 0;
                        }
                    }



                }


            }
        }
    }
}