@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@font-face {
	font-family: "SFProRegular"; /* Choose a unique font name */
	src: url("./../public/fonts/SFProDisplay-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "SFProRegular";
	text-decoration: none;
}
a,
button {
	cursor: pointer;
}

body {
	max-width: 1920px;
	margin: 0 auto;
}

.fullHeightContainer {
	height: 100vh;
}

::-webkit-scrollbar {
	width: 5px;
	height: 7px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.searchBtn {
	background-color: #4367b0 !important;
}

.resetBtn {
	background-color: #ed1414 !important;
}

label,
option {
	text-transform: capitalize !important;
}
thead tr th {
	text-transform: capitalize !important;
}

@media only screen and (min-width: 1400px) {
	.title {
		font-size: 24px !important;
	}

	tr,
	td,
	th {
		font-size: 14px !important;
	}

	button,
	input,
	/* label, */
	a,
	option,
	select {
		font-size: 14px !important;
	}
}
@media only screen and (max-width: 1400px) {
	.title {
		font-size: 20px !important;
	}

	tr,
	td,
	th {
		font-size: 12px !important;
	}

	button,
	input,
	label,
	a,
	option,
	select {
		font-size: 12px !;
	}
}

input,
select {
	color: #000 !important;
}

input:disabled,
select:disabled {
	background-color: #e6e6e6 !important;
}

.dashboardContent {
	max-height: 620px;
	overflow: auto;
}
