.myStudents {
	.modal-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.7);
		.modal-content {
			height: fit-content;
			border-radius: 5px;
			max-width: 50%;
			padding: 1rem 0;
			position: relative;
			.reasonTitle {
				font-size: 1.7rem;
				font-weight: 700;
				color: rgb(20, 20, 20);
				text-align: center;
			}
			.DetailsContainer {
				max-height: 25rem;
				padding: 0 2rem;
				margin: 0 0.5rem;
				overflow-y: scroll;
				.details {
					width: 100%;
					.absentDetailsTable {
						width: 100%;
						tbody {
							tr {
								.AbsentDates {
									width: 7.2rem;
								}
							}
						}
					}
				}
			}
			.closeBtn {
				font-size: 1.5rem;
				font-weight: 700;
				position: absolute;
				background-color: transparent;
				border: none;
				padding: 0.7rem 1rem;
				right: 0;
				top: 0;
				border-radius: 50%;
			}
		}
	}
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 28px;
				font-weight: 600;
				letter-spacing: 0.28px;
			}

			.batchStudentListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin: 20px 0;

				.crmListContainer {
					.topInput {
						display: flex;
						gap: 20px;
					}
				}

				.searchBtnContainer {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;

					.searchBtn {
						border-radius: 36px;
						background-color: #4367b0;
						color: #fff;
						text-align: center;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						padding: 12px 28px;
						border: none;
					}

					.resetBtn {
						background-color: #fff;
						color: #4367b0;
						border: 1px solid #4367b0;
					}

					.active {
						background-color: #4367b0;
					}
				}

				.inputContainer {
					width: 25%;
				}

				label {
					color: #000000;
					font-weight: 500;
					font-size: 16px;
					letter-spacing: 0.16px;
				}

				input,
				select {
					margin-top: 5px;
					color: #4367b0;
					width: 100%;
					// width: 470px;
					padding: 8px 15px;
					font-size: 16px;
					font-weight: 400;
					letter-spacing: 0.16px;
					border-radius: 22px;
					border: 1px solid #bfbfbf;
					background-color: #fff;
				}

				input::placeholder {
					color: #bfbfbf;
				}

				input:focus {
					outline: 1px solid #4367b0;
				}

				.topSearchBar {
					margin: 15px 0;

					.searchInputs {
						display: flex;
						gap: 20px;
						align-items: center;

						flex-wrap: wrap;
						margin: 10px 0;

						.inputContainer {
							width: 23%;
						}
					}
				}

				.courses {
					overflow-x: auto;
					margin: 10px 0;
					table {
						border-collapse: collapse;
						border-spacing: 0;
						width: 100%;
					}

					tr th {
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						white-space: nowrap;
					}

					tr td {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
						white-space: nowrap;
						border-right: 1px solid #f5f1f1;
					}

					th,
					td {
						text-align: left;
						padding: 8px;
					}

					tr {
						border-bottom: 1px solid #bfbfbf;
					}

					.selected {
						background-color: #edf0f8;
					}

					.btnRow {
						display: flex;
						gap: 5px;
						justify-content: center;
						align-items: center;

						a {
							padding: 5px 10px;
						}
					}

					//   tr:nth-child(even){background-color: #f2f2f2}
				}
			}
		}
	}

	.actionBtn {
		// width: 200px;
		display: flex;
		gap: 10px;
	}

	.pagination {
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
		margin: 30px auto;

		button {
			border-radius: 50%;
			border: 1px solid #e6e6e6;
			background: #fff;
			height: 45px;
			width: 45px;
			color: #000;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
		}
		button:disabled {
			background-color: #eee;
			color: gray;
		}

		.active {
			border-radius: 6px;
			background: #000;
			color: #fff;
		}

		select {
			border: 1px #f5f5f5;
			border-radius: 10px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			padding: 10px;
		}
	}

	.fullScreenConatiner {
		display: flex;
		margin: 20px 0;
		justify-content: flex-end;
		.fullScreenAction {
			display: flex;
			gap: 10px;
			align-items: center;
			color: #4367b0;
			font-size: 16px;
			font-weight: 500;
			margin-left: auto;
			cursor: pointer;

			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}
	}
	.fullScreenActive {
		height: 100%;
		display: flex;
		background-color: #fff;
		flex-direction: column;
		padding: 20px;
		// justify-content: center;
	}

	.paginationWrapper {
		position: "absolute";
		bottom: 20px;
		right: 20px;
	}
}

.batchTable {
	width: 100%;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}

	tr th {
		color: #000000;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0.16px;
		white-space: nowrap;
	}

	tr td {
		display: flexbox;
		color: #000;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.16px;
		white-space: nowrap;
	}

	th,
	td {
		text-align: left;
		padding: 8px;
	}

	tr {
		border-bottom: 1px solid #bfbfbf;
	}

	tr th:nth-child(7) {
		text-align: center;
	}

	tr th:nth-child(8) {
		text-align: center;
	}

	td button {
		border: none;
		padding: 10px;
		border-radius: 8px;
		margin: 0 2px;
		background-color: #1251f3;
		font-size: 14px;
	}

	td a {
		color: white;
	}
}
