.dashboard {
	.modal-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		.modal-content {
			height: fit-content;
			padding: 2rem;
			border-radius: 5px;
			width: 30%;
			.reasonTitle {
				font-size: 1.5rem;
				font-weight: 500;
				text-align: center;
			}
			.submitReasonBtn {
				font-size: 1.1rem;
				font-weight: 700;
				letter-spacing: 0.7px;
				background-color: #4367b0;
				color: #ffffff;
				border: none;
				padding: 0.8rem 0;
				border-radius: 10px;
			}
		}
	}
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;
			.imageHolder {
				position: relative;
				width: fit-content;

				.enlarge {
					position: absolute;
					top: 0;
					right: 0;
					width: 30px;
				}
			}

			.title {
				h2 {
					color: #000;
					font-size: 28px;
					font-weight: 600;
					letter-spacing: 0.28px;
				}
				.desc {
					font-size: 14px;
				}
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.logoutBtn {
					color: #fff;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
					border: none;
					background-color: #d80032;
					border-radius: 25px;
					padding: 0 10px;
					height: 45px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					img {
						height: 20px;
						width: 20px;
					}
				}
			}

			.dashboardContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				gap: 40px;
				overflow-x: auto;
				margin-top: 10px;
			}

			.shortcutCards {
				display: flex;
				gap: 10px;
				flex-wrap: wrap;
				justify-content: center;
				margin-top: 20px;

				.card {
					display: flex;
					justify-content: space-between;
					align-items: center;
					background-color: #edf0f8;
					padding: 20px;
					border-radius: 20px;
					cursor: pointer;
					width: 20em;

					.right {
						img {
							background-color: #4367b0;
							border-radius: 100%;
						}
					}
				}
			}
		}
	}

	.infoDetails {
		margin-top: 40px;

		h2 {
			font-size: 20px;
		}
	}

	.batchDetails,
	.classDetails,
	.calendarDetails {
		border: 1px solid #bfbfbf;
		border-radius: 10px;
		padding: 20px;
		margin-top: 20px;
	}

	.details {
		overflow-x: auto;
		margin: 20px 0;

		.dashboardDetailsTable {
			width: 100%;
			border-collapse: collapse !important;
			border-spacing: 0 !important;
			width: 100%;

			tr th {
				color: #000000;
				font-size: 13px;
				font-weight: 500;
				letter-spacing: 0.16px;
				// background-color: red;
				white-space: nowrap;
				// border-bottom: 1px solid black;
				border-collapse: collapse;
				border: 1px solid #bfbfbf;
			}

			tr td {
				display: flexbox;
				color: #000;
				font-size: 16px;
				font-weight: 400;
				letter-spacing: 0.16px;
				white-space: nowrap;
				border: 1px solid #bfbfbf;
			}

			th,
			td {
				text-align: left;
				padding: 8px;
			}

			tr {
				border-bottom: 1px solid #bfbfbf;
			}
		}
	}
}
