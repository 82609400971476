  label {
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.16px;
  }
  .input{
    width: 100%;
  }
  input,
  textarea,
  select {
      width: 100%;
      color: black;
      // width: 470px;
      padding: 10px 15px 10px 15px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.16px;
      border-radius: 20px;
      border: 1px solid #BFBFBF;
      background-color: #fff;
  }

  input::placeholder {
      color: #BFBFBF;
  }

  input:focus {
      outline: 1px solid black;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }