.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 1;
}

.qr-modal-content {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    height: 100vh;

    .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #BFBFBF;
        padding: 30px 20px;

        .left {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;

            .title {
                color: #000;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0.24px;
            }

        }

        .crossIcon {
            border: 1px solid #BFBFBF;
            border-radius: 50%;
            padding: 5px 15px;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.24px;
        }
    }

    .modalBody {
        // padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;

        .heading {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.24px;
        }

        .notice {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 24px 40px;
            border-radius: 14px;
            background: #FCE6EB;

            p {
                color: #D80032;
                text-align: center;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0.2px;
            }
        }

        .img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            // padding: 24px 40px;
            border-radius: 14px;
            // height: 25em;
            width: 100%;

        }
    }

    .goBackBtn {
        border-radius: 36px;
        border: 1px solid #4367B0;
        background: #fff;
        display: flex;
        gap: 10px;
        color: #4367B0;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;
        margin: 0 auto;
        padding: 10px 20px;
    }


}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.modal-overlay,
.qr-modal-content {
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

.modal-overlay {
    animation-name: fadeIn;
}

.qr-modal-content {
    animation-name: slideInRight;
}

.modal-overlay.closed {
    animation-name: fadeOut;
}

.qr-modal-content.closed {
    animation-name: slideOutRight;
}