@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.FeaturedInPageContainer {
	width: 90%;
	margin: auto;
	position: relative;
	padding: 2rem 2rem 6rem;
	h2 {
		width: 100%;
		text-align: center;
		@include fontStyle(3rem, 600, 3.6rem);
		padding-bottom: 3rem;
	}

	.slick-list {
		margin: auto;
		width: 96%;
	}
	div.Featureditem {
		@include flex(row, "", center, center);
		position: relative;
		height: 5.2rem;

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}
}

@media (max-width: 768px) {
	.FeaturedInPageContainer {
		padding: 2rem 4rem;
		h2 {
			@include fontStyle(2.4rem, 600, 2.4rem);
			margin-bottom: 0;
			padding-bottom: 1rem;
		}
		.slick-list {
			width: 100%;
		}
		div.Featureditem {
			height: fit-content;
			width: fit-content;
			img {
				object-fit: contain;
				width: 70%;
				height: 70%;
				margin: auto;
			}
		}
	}
}
