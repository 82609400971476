.bankDetails {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .bankDetailsContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        gap: 40px;
        overflow-x: auto;
        margin-top: 10px;

        .bankListTable {
            width: 100%;
            overflow-x: auto;

          table {
            width: 100%;
            border-radius: 14px;
            border: 1px solid var(--Secondary-10, #e6e6e6);
          }

          th {
            border-bottom: 1px solid #bfbfbf;
            // color: var(--Secondary-50, #808080);
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            text-align: left;
            padding: 10px;

            span {
              font-size: 14px;
            }
          }

          tr td {
            padding: 10px;
            font-size: 14px;
            color: var(--Secondary-50, #4c4a4a);
          }

          .updateBtn {
            cursor: pointer;
            color: #4367B0;
          }

          .editBtn {
            color: #FFF;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            border: none;
            background-color: #4367B0;
            border-radius: 8px;
            padding: 0 10px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          
            img {
              height: 20px;
              width: 20px;
            }
          
          }
        }
      }
    }
  }
}

