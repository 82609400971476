.addBatch {
    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .addBatchContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                gap: 40px;

                .addBatchForm {
                    display: flex;
                    gap: 20px;
                    width: 30%;
                    flex-direction: column;
                    justify-content: space-between;

                    .formInput {
                        width: 100%;
                    }

                    label {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;

                        span {
                            color: #D80032;
                        }
                    }

                    input,
                    select {
                        color: #BFBFBF;
                        width: 100%;
                        padding: 13px 24px;

                        border: 1px solid #BFBFBF;
                        border-radius: 36px;
                        background: #FFF;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                        margin-top: 5px;
                    }

                    input::placeholder {
                        color: #BFBFBF;
                    }

                    input:focus {
                        outline: 1px solid #4367B0;
                    }

                }

                .nextBtn {
                    width: 100%;
                    margin: auto;
                    padding: 15px;
                    align-items: center;
                    border-radius: 36px;
                    background: #4367B0;
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    border: none;
                    position: relative;

                    .forwardArrow {
                        position: absolute;
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 10px;
                        position: absolute;
                        right: 25px;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                    }
                }

                .addBatchRight {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .editorName {
                        border: 1px solid #111;
                        height: 100px;
                    }

                    .mainTitle {
                        border-radius: 8px;
                        background: var(--Primary-100, #4367B0);
                        padding: 15px;
                        color: var(--White-100, #FFF);
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0.48px;

                    }

                    .installmentContainer {

                        table {
                            width: 100%;
                            border-radius: 14px;
                            border: 1px solid var(--Secondary-10, #E6E6E6);
                        }


                        th {
                            border-bottom: 1px solid #BFBFBF;
                            color: black;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.16px;
                            text-align: left;
                            padding: 10px;

                            span {
                                font-size: 14px;
                            }
                        }

                        td {
                            padding: 10px;
                        }

                        input {
                            border-radius: 8px;
                            border: 1px solid var(--Secondary-25, #BFBFBF);
                            background: var(--White-100, #FFF);
                            color: black;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                            padding: 10px;
                        }
                    }

                    .installmentBtn {

                        .addInstallment {
                            border-radius: 36px;
                            background: var(--Primary-100, #4367B0);
                            padding: 10px 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;

                            color: var(--White-100, #FFF);
                            text-align: center;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.16px;
                            border: none;

                        }

                        p {
                            color: var(--Secondary-50, #808080);
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                        }
                    }

                    .tcContainer {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        .tc {
                            border-radius: 12px;
                            border: 1px solid var(--Secondary-25, #BFBFBF);
                            padding: 15px 25px;

                            display: flex;
                            gap: 20px;
                            width: 100%;
                            flex-direction: column;

                            .accordionTitle {
                                display: flex;
                                justify-content: space-between;
                                cursor: pointer;
                                width: 100%;
                                align-items: center;

                                .tcTitle,
                                .icon {
                                    color: var(--Secondary-100, #000);
                                    font-size: 16px;
                                    font-weight: 600;
                                    letter-spacing: 0.16px;
                                }

                                .icon {
                                    font-size: 24px;
                                }

                            }
                        }
                    }
                }

            }
        }
    }
}