/** @format */

.subTitle {
   display: flex;
   gap: 2rem;
   margin-top: 1rem;
}
.subTitleMargin {
   margin-top: 2.5rem;
}
.radioInputsContainer {
   display: flex;
   flex-direction: column;
   border: 1px solid rgb(223, 223, 223);
   padding: 1.5rem;
   border-radius: 1rem;
   margin-top: 1.5rem;
}
.radioContainer {
   margin-top: 1rem;
}
.saveBtn {
   background-color: #4367b0;
   padding: 0.8rem 1rem;
   border-radius: 1.4rem;
   width: 5rem;
   outline: none;
   color: #fff;
   border: 0;
   margin-top: 1rem;
}
.toggleButtons {
   margin-bottom: 1rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.toggleRightText {
   font-weight: 600;
   font-size: 1.2rem;
   display: flex;
   gap: 2rem;
}
.notesContainer {
   display: flex;
   gap: 2rem;
}

.uploadArea {
   width: 100%;
   border-radius: 8px 8px 8px 8px;
   border: 1px solid #BFBFBF;
   background-color: #FFF;
   display: flex;
   flex-direction: column;
   gap: 10px;

   .titleHomework {
       border-radius: 8px;
       background: var(--Secondary-100, #000);
       padding: 10px;

       color: var(--White-100, #FFF);
       font-size: 16px;
       font-weight: 500;
       letter-spacing: 0.16px;
   }

   .uploadInfo {
       color: var(--Secondary-100, #000);
       text-align: center;
       font-size: 12px;
       font-weight: 400;
       line-height: 16px;
       letter-spacing: 0.12px;
   }

   .uploadHomeworkBtn {
       border-radius: 8px;
       background: var(--Primary-100, #4367B0);
       padding: 8px 12px;
       display: flex;
       justify-content: center;
       align-items: center;
       gap: 4px;

       color: var(--White-100, #FFF);
       font-size: 16px;
       font-weight: 500;
       letter-spacing: 0.16px;
       border: none;
       width: 130px;
       margin: 0 auto;
   }

   .uploadContainer {
       padding: 15px;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       gap: 15px;
   }

   .fileNames {
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       gap: 10px;

       .fileName {
           color: var(--Primary-100, #4367B0);
           text-align: center;
           font-size: 16px;
           font-weight: 500;
           letter-spacing: 0.16px;
           text-decoration-line: underline;
           display: flex;
           align-items: center;
           justify-content: center;
           gap: 20px;

           .removeBtn {
               color: var(--Error-100, #D80032);
               display: flex;
               align-items: center;
               justify-content: center;
               gap: 10px;
               text-decoration: none;
               cursor: pointer;
           }
       }
   }
}