.image-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;


      
  .modal-content {
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 80%;
    height: 70%;

    img {
      width: 100%;
      height: 90%;
      object-fit: contain;
    }
    button {
      width: 30%;
      margin: 0 auto;

      color: #4367B0;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
      border: 1px solid #4367B0;
      background-color: #fff;
      border-radius: 8px;
      padding: 0 10px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  
  &.open {
    display: flex;
  }
  
  }
