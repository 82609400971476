@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.AboutCobtainer {
	@include flex(column, 1rem, center, center);
	padding: 4rem 8rem 0;
	letter-spacing: 1.1px;
	background-color: #0a0d14;
	h2 {
		@include fontStyle(3rem, 600, 3.6rem);
		color: #ffffff;
	}
	.AboutDetails {
		@include flex(row, 4rem, center, center);
		img.aboutImg {
			height: 39.27rem;
			width: 20.4rem;
		}
		.AboutDesc {
			@include flex(column, 1rem, center, start);
			width: 25rem;
			p {
				@include fontStyle(1.125rem, 500, 1.875rem);
				color: #ffffff;
				span {
					@include fontStyle("", 700, "");
				}
			}
			ol {
				@include fontStyle(1.1875rem, 800, "");
				margin-left: 3rem;
				color: #ffffff;
				li {
					margin-top: 0.5rem;
				}
			}
			button.btn {
				@include flex("", 0.5rem, center, center);
				@include fontStyle(1.5rem, 600, 1.125rem);
				border: none;
				border-radius: 0.75rem;
				padding: 1.25rem 2.5rem;
				background-color: #4367b0;
				color: #ffffff;
			}
		}
		div.AboutDescDiv {
			margin-bottom: 6rem;
		}
	}
}
@media (max-width: 1315px) {
	.AboutCobtainer {
		padding: 4rem 2rem 0;
		.AboutDetails {
			width: 100%;
			@include flex(row, 0rem, space-between, center);
			img.aboutImg {
				height: 39.27rem;
				width: 20.4rem;
			}
		}
	}
}

@media (max-width: 1024px) {
	.AboutCobtainer {
		padding: 4rem 2rem;
		.AboutDetails {
			width: 100%;
			@include flex(column, 5rem, space-between, center);
			img.aboutImg {
				width: 17.11rem;
				height: 32.95rem;
				border-radius: 0.75rem;
			}
			.AboutDesc {
				width: 90%;
			}
		}
	}
}
@media (max-width: 768px) {
	.AboutCobtainer {
		gap: 1rem;
		padding: 4rem 0 1.4rem;
		h2 {
			@include fontStyle(2rem, 600, 2.4rem);
			text-align: center;
		}
		.AboutDetails {
			@include flex(column, 4rem, "", "");
			border-top: 1px solid #838282;
			padding: 4rem 1.5rem;
			width: 100%;
			margin-top: 2rem;
			position: relative;
			.AboutDesc {
				gap: 1rem;
				width: 100%;
				p {
					@include fontStyle(1rem, 400, 1.75rem);
					text-align: left;
				}

				ol {
					@include fontStyle(1.0625rem, 700, 1.75rem);
					text-align: left;
					li {
						margin-top: 0.5rem;
					}
				}
				button.btn {
					@include fontStyle(1rem, 600, 1rem);
					width: 11.9375rem;
					height: 3rem;
					padding: 1rem 2.5rem;
					border-radius: 0.75rem;
					margin-top: 1rem;
					text-align: center;
				}
			}
			div.AboutDescDiv {
				margin-bottom: 0;
			}
			span.Readmore {
				bottom: -1rem;
				position: absolute;
				div {
					@include flex(column, 0, center, center);
					p {
						@include fontStyle(1.2rem, 400, "");
						order: 1;
						color: #ffffff;
					}
					img {
						order: 2;
						height: 2rem;
						width: 2rem;
					}
				}
				div.readLess {
					p {
						order: 2;
						padding-bottom: 0.5rem;
					}
					img {
						order: 1;
					}
				}
				div.readMore {
					p {
						order: 1;
					}
					img {
						order: 2;
					}
				}
			}
			p.toHide,
			ol.toHide,
			div.toHide,
			img.toHide {
				overflow: hidden;
				max-height: 1000px;
				animation: slideUp 0.5s ease-out forwards;
			}
			div.toShow,
			p.toShow,
			ol.toShow,
			img.toShow {
				display: block;
				overflow: hidden;
				max-height: 0;
				animation: slideDown 0.5s ease-in-out forwards;
			}
			@keyframes slideDown {
				from {
					max-height: 0;
				}
				to {
					max-height: 1000px;
				}
			}
			@keyframes slideUp {
				from {
					max-height: 1000px;
				}
				to {
					max-height: 0;
					display: none;
				}
			}
		}
	}
}
