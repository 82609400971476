.terms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.TermsModal {
  .terms-modal-content {
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: space-between;
      gap: 20px;
      width: 50%;
      height: 90vh;


      .tandc-header{
          border-radius: 10px 10px 0 0;
          background: var(--Primary-100, #4367B0);
          padding: 24px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .tandc-title {
              color: var(--White-100, #FFF);
              font-size: 24px;
              font-weight: 500;
              letter-spacing: 0.24px;
              text-align: center;
          }

          .closeBtn {
              color: #000;
              background-color: #fff;
              padding: 10px;
              border-radius: 50%;
              height: 30px;
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;


          }
      }

      .body {
          width: 95%;
          display: flex;
          margin: 0 auto;
          flex-direction: column;
          // align-items: center;
          // justify-content: center;
          gap: 20px;
          padding: 0 20px;
          // height: 300px;
          overflow-y: auto;
          height: 100%;

         
      }

      .saveButtonWrapper {
          padding: 20px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;

          .termCheckbox {
            display: flex;
            gap: 5px;
            align-items: center;

            input {
              width: 20px;
              height: 20px;
            }
          }

          .approveBtn {
              border-radius: 36px;
              background: var(--Primary-100, #4367B0);
              width: 110px;
              height: 45px;
              color: var(--White-100, #FFF);
              text-align: center;
  
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.16px;
              border: none;
          }
      }




  }
}



@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

.modal-overlay,
.modal-content {
  animation: fadeIn 0.3s ease-in-out;
}