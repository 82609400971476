.NoDataFoundContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .NoDataFoundContentWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-weight: 500;
    }
}