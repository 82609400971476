// VideoModal.scss
.video-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    &.open {
      display: flex;
    }
  
    .modal-content {
      position: relative;
      width: 80%;
      max-height: 80%;
      overflow: hidden;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      video,iframe {
        display: block;  /* iframes are inline by default */   
        height: 80vh;  /* Set height to 100% of the viewport height */   
        width: 80vw;  /* Set width to 100% of the viewport width */     
        border: none;
      }
  
      button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #fff;
        border: none;
        padding: 8px;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
  