@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.HowToPageContainer {
	@include flex(column, 1.5rem, center, center);
	background-color: #ffffff;
	padding: 7rem 8rem 3rem;

	.titleTop {
		@include fontStyle(3.5rem, 300, 3.85rem);
	}

	.mainTitle {
		@include fontStyle(4.75rem, 600, 5.225rem);
	}

	.howTolistConatiner {
		@include flex(row, 1.5rem, center, center);
		flex-wrap: wrap;
		margin-top: 3rem;
		padding-bottom: 3rem;
		width: 80%;
		.hpwTolist {
			@include flex(row, 0, start, center);
			@include fontStyle(1.2rem, 500, 1.4rem);
			background-color: #edeeee;
			padding: 1.5rem;
			width: 40%;
			color: #4267ad;
			border-radius: 25px;
			box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px,
				rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px,
				rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
		}
	}
	button {
		@include flex("", 0.5rem, center, center);
		@include fontStyle(1.5rem, 600, 1.125rem);
		border: none;
		border-radius: 0.75rem;
		padding: 1.25rem 2.5rem;
		background-color: #4367b0;
		color: #ffffff;
	}
}
@media (max-width: 1350px) {
	.HowToPageContainer {
		padding: 7rem 0 3rem;
	}
}
@media (max-width: 1024px) {
	.HowToPageContainer {
		@include flex(column, 1.5rem, center, center);
		background-color: #ffffff;
		padding: 7rem 1rem 3rem;
		.titleTop {
			@include fontStyle(3rem, 300, 3.85rem);
			text-align: center;
		}
		.howTolistConatiner {
			width: 100%;
		}
		.mainTitle {
			@include fontStyle(4rem, 600, 5.225rem);
			text-align: center;
		}
	}
}
@media (max-width: 768px) {
	.HowToPageContainer {
		@include flex(column, 1.5rem, center, center);
		padding: 3rem 1rem;
		.titleTop {
			@include fontStyle(2rem, 300, 2.5rem);
		}

		.mainTitle {
			@include fontStyle(3rem, 600, 3rem);
			text-align: center;
		}
		.howTolistConatiner {
			@include flex(column, 1.5rem, center, center);
			width: 97%;
			margin-top: 1rem;
			padding-bottom: 1rem;
			.hpwTolist {
				width: 100%;
			}
		}

		button {
			@include fontStyle(1rem, 600, 1rem);
			height: 3rem;
			padding: 1rem 1.5rem;
		}
	}
}
