.addPartner {
    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .topDetails {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .row {
                    display: flex;
                    gap: 20px;
                }
            }

            .addMemberContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 40px;

                .titleBox {
                    display: flex;
                    justify-content: space-between;

                    p {
                        color: var(--Secondary-100, #000);
                        font-size: 20px;
                        font-weight: 600;
                        letter-spacing: 0.2px;
                    }

                    .memberListBtn {
                        border-radius: 33px;
                        background: var(--Primary-100, #4367B0);
                        padding: 12px 20px;
                        color: var(--White-100, #FFF);
                        text-align: center;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;
                    }
                }

                .addMemberList {
                    table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        width: 100%;
                    }

                    tr th {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;
                        white-space: nowrap !important;
                    }

                    tr td {
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                        white-space: nowrap !important;
                        white-space: nowrap;
                        min-width: 180px;


                        .addBtn {
                            color: #FFF;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.16px;
                            border: none;
                            background-color: #4367B0;
                            border-radius: 8px;
                            padding: 0 10px;
                            height: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                          
                            img {
                              height: 20px;
                              width: 20px;
                            }
                        }

                    }
                    tr td:first-child {
                        min-width: 50px;
                    }

                    th,
                    td {
                        text-align: left;
                        padding: 8px;

                    }

                    tr {
                        border-bottom: 1px solid #BFBFBF;

                    }


                    label {
                        color: #000000;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 0.16px;
                    }

                    input,
                    select {
                        color: #4367B0;
                        // width: 470px;
                        padding: 15px;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                        border-radius: 22px;
                        border: 1px solid #BFBFBF;
                        background-color: #fff;
                    }

                    select {
                        // width: 150px;
                    }

                    input::placeholder {
                        color: #BFBFBF;
                    }

                    input:focus {
                        outline: 1px solid #4367B0;
                    }

                    //   tr:nth-child(even){background-color: #f2f2f2}
                }





            }
        }


    }
}

