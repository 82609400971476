.batchStudentList {
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 28px;
				font-weight: 600;
				letter-spacing: 0.28px;
			}

			.batchStudentListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin: 20px 0;

				.topSearchBar {
					margin: 15px 0;

					.searchInputs {
						display: flex;
						gap: 20px;
						align-items: flex-end;

						flex-wrap: wrap;
						margin: 10px 0;

						.searchField {
							position: relative;

							.searchDropdown {
								position: absolute;
								width: 100%;
								background-color: #fff;
								border: 1px solid #808080;
								top: 40px;
								border-radius: 10px;

								max-height: 250px;
								overflow: auto;

								p {
									color: #000;
									padding: 5px 10px;
									border-bottom: 1px solid #bfbfbf;
									font-size: 14px;
								}

								p:last-child {
									border-bottom: none;
								}

								p:hover {
									background-color: #f0f0f0;
								}
							}
						}

						.inputContainer {
							min-width: 23%;

							label {
								color: #000000;
								font-weight: 500;
								font-size: 16px;
								letter-spacing: 0.16px;
								white-space: nowrap;
							}

							input,
							select {
								margin-top: 5px;
								color: #4367b0;
								width: 100%;
								padding: 8px 15px;
								font-size: 16px;
								font-weight: 400;
								letter-spacing: 0.16px;
								border-radius: 22px;
								border: 1px solid #bfbfbf;
								background-color: #fff;
							}

							input::placeholder {
								color: #bfbfbf;
							}

							input:focus {
								outline: 1px solid #4367b0;
							}
						}

						.searchBtnContainer {
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;

							.searchBtn {
								border-radius: 36px;
								background-color: #d0d9eb;
								color: #fff;
								text-align: center;
								font-size: 16px;
								font-weight: 500;
								letter-spacing: 0.16px;
								padding: 12px 28px;
								border: none;
							}

							.active {
								background-color: #4367b0;
							}
						}
					}
				}

				.courses {
					overflow-x: auto;
					margin: 10px 0;
					table {
						border-collapse: collapse;
						border-spacing: 0;
						width: 100em;
					}

					tr th {
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						white-space: nowrap;
					}

					tr td {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
						white-space: nowrap;
					}

					th,
					td {
						text-align: left;
						padding: 8px;
					}

					tr {
						border-bottom: 1px solid #bfbfbf;
					}

					.btnRow {
						display: flex;
						gap: 5px;
						justify-content: flex-start;
						align-items: center;

						a {
							padding: 5px 10px;
						}
					}

					//   tr:nth-child(even){background-color: #f2f2f2}
				}
			}
			.paginationWrapper {
				position: "absolute";
				bottom: 20px;
				right: 20px;
			}
		}
	}

	.actionBtn {
		// width: 200px;
		display: flex;
		gap: 10px;
	}

	.pagination {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		align-items: center;
		margin: 30px auto;

		button {
			border-radius: 50%;
			border: 1px solid #e6e6e6;
			background: #fff;
			height: 45px;
			width: 45px;
			color: #000;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
		}
		button:disabled {
			background-color: #eee;
			color: gray;
		}

		.active {
			border-radius: 6px;
			background: #000;
			color: #fff;
		}

		select {
			border: 1px #f5f5f5;
			border-radius: 10px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			padding: 10px;
		}
	}
}

.batchTableBtn {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	background-color: #00a65a;
	border-radius: 8px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.batchStudentList {
	.batchTableBtn {
		padding: 10px !important;
	}
}

.resetBtn {
	border-radius: 36px;
	background-color: #ed8aa1;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.16px;
	padding: 15px 30px;
	border: none;
}

.batchStudentTable {
	width: 100%;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}

	tr th {
		color: #000000;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0.16px;
	}

	tr td {
		display: flexbox;
		color: #000;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.16px;
	}

	th,
	td {
		text-align: left;
		padding: 8px;
	}

	tr {
		border-bottom: 1px solid #bfbfbf;
	}

	tr th:nth-child(7) {
		text-align: center;
	}

	tr th:nth-child(8) {
		text-align: center;
	}

	td button {
		border: none;
		padding: 10px;
		border-radius: 8px;
		margin: 0 2px;
		background-color: #1251f3;
		font-size: 14px;
	}

	td a {
		color: white;
	}
}
