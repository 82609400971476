@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.PaymentContainer {
	@include flex("", "", center, center);
	background-color: #faf7f7;
	height: 100vh;
	.payment {
		@include flex(column, 0.5rem, center, center);
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		width: 60%;
		padding: 1.5rem 1rem;
		background-color: #ffffff;
		border-radius: 1rem;
		height: fit-content;
		.paymentStatus {
			@include fontStyle(1.375rem, 400, 1.4375rem);
			color: #474747;
		}
		.amount {
			@include fontStyle(2rem, 600, 1.9375rem);
			padding-bottom: 1.5rem;
			margin-bottom: 1rem;
			text-align: center;
			width: 17.5625rem;
			border-bottom: 1px solid #ededed;
		}
		img.successIcon {
			padding-bottom: 0.5rem;
		}
		.paymentDetails {
			@include flex(column, 1rem, center, center);
			width: 55%;
			hr {
				color: #ededed;
				margin: 0.5rem;
			}
			.basicDetails,
			.AmountDetials {
				@include flex(column, 1rem, space-between, center);
				width: 100%;
				div {
					@include flex(row, "", space-between, center);
					@include fontStyle(0.5rem, "", 1.0625rem);
					width: 100%;

					p {
						@include fontStyle(1.125rem, 400, 1.0625rem);
						text-align: left;
						color: #707070;
					}
					span {
						@include fontStyle(1.125rem, 400, 1.0625rem);
						font-weight: 500;
						color: #121212;
					}
				}
			}
		}
		a.DownloadBtn {
			@include fontStyle(0.625rem, 400, 0.9375rem);
			background-color: transparent;
			border: none;
			color: #121212;
			margin-top: 0.5rem;
			img {
				height: 0.75rem;
				width: 0.75rem;
				margin-right: 0.3rem;
			}
		}
		button {
			@include flex(row, 0.5rem, center, center);
			border-radius: 0.75rem;
			@include fontStyle(1.5rem, 600, 1.125rem);
		}
		button.homeBtn {
			border: none;
			margin-top: 1rem;
			padding: 0.625rem 2.5rem;
			background-color: #4367b0;
			color: #ffffff;
		}

		button.retryBtn {
			padding: 0.6875rem 2.8125rem;
			border: none;
			margin-top: 1rem;
			color: #ffffff;
			background-color: #4367b0;
		}

		button.cancelBtn {
			margin-top: 0.4rem;
			padding: 0.625rem 2.5rem;
			background-color: transparent;
			color: #4367b0;
			border: 1px solid #4367b0;
		}
	}
}
@media (max-width: 768px) {
	.PaymentContainer {
		.payment {
			width: 90%;
			.paymentStatus {
				@include fontStyle(1rem, 400, 1.4175rem);
				text-align: center;
			}
			.amount {
				@include fontStyle(1.375rem, 600, 1.89rem);
				text-align: center;
			}
			.paymentDetails {
				width: 100%;
				hr {
					margin: 0.5rem;
				}
				.basicDetails,
				.AmountDetials {
					width: 100%;
					div {
						p {
							@include fontStyle(0.75rem, 400, 1.0625rem);
							text-align: left;
						}
						span {
							@include fontStyle(0.75rem, 500, 1.0625rem);
							text-align: center;
						}
					}
				}
			}
			a.DownloadBtn {
				@include fontStyle(0.534375rem, 400, 0.91625rem);
				text-align: center;
			}
			button {
				@include fontStyle(0.7225rem, 600, 0.7225rem);
				text-align: center;
			}

			button.retryBtn {
				padding: 0.7225rem 2.1875rem;
			}

			button.cancelBtn {
				padding: 0.7225rem 1.805625rem;
			}
			button.homeBtn {
				width: 6.9375rem;
				height: 2.194375rem;
				padding: 0.7225rem 1.805625rem 0.7225rem 1.805625rem;
				border-radius: 0.5425rem;
			}
		}
	}
}

.loader {
	border: 1rem solid #f3f3f3;
	border-top: 1rem solid #3498db;
	border-radius: 50%;
	width: 7.5rem;
	height: 7.5rem;
	animation: spin 2s linear infinite;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
