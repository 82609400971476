.DashboardBanner{
    width: 100%;
    background-color: #EDF0F8;
    padding:10px 15px 10px 15px;
    border-radius: 10px;


    .bannerContainer{
         display: flex;
         align-items: center;
         justify-content: space-between;
         
         .bannerLeftSection{
            display: flex;
            flex-direction: column;
            gap: 5px;
           
            .bannerTopContent{
                display: flex;
                align-items: center;
                width: fit-content;
                gap: 5px;

                .bannerIconWrapper{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .bannerIcon{
                        width: 20px;
                    }
                }

                .bannerTitle{
                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: normal;
                    
                }
              
            }

            .bannerBottomContent{
                display: flex;

                .bannerBottomItems{
                    font-size: 12   px;
                    font-weight: 400;
                }
            }

         }
         

    }
}