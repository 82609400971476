.courseCardContainer{
    width: 100%;
    border-radius: 14px;
    border: 1px solid #E6E6E6;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:5px 0px 5px 0px;


    .headerChildrenOne {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.9rem;
        border-bottom: 1px solid #e6e6e6;
        }
    .noBorder {
        border: none;
        }

    .courseCardButtonWrapper{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}