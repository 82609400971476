.thankYouContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    gap: 10px;

    img {
        width: 250px;
    }
    h1 {
        font-size: 50px;
    }
    .navBtn {
        background-color: #4367B0;
        border: none;
        border-radius: 10px;
        padding: 10px 30px;
        color: #fff;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 600px){
    .thankYouContainer {
        img {
            width: 200px;
        }
        h1 {
            font-size: 40px;
        }
    }}