.mainOutlet {
    width: 100%;

    .bodyOutlet {
        display: flex;

        .mainRoutes {
            width: calc(100% - 230px);   
    }
    }
}