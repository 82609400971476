.videoRequestModal {

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  /* Some basic CSS overrides */
  body {
    line-height: 1.5;
    min-height: 100vh;
    font-family: "Outfit", sans-serif;
    color: #2d232e;
    background-color: #c8c0bd;
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
  }

  a {
    color: inherit;
  }

  /* End basic CSS override */

  * {
    scrollbar-width: 0;
  }

  *::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #ddd;
    border: 4px solid #fff;
  }

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.25);
  }

  .modal-container {
    max-height: 90vh;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
    @media (max-width: 600px) {
      width: 90%;
    }
  }

  .modal-container-header {
    padding: 16px 32px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #4367B0;
    color: white;

    svg {
      background-color: white;
      border-radius: 100%;
    }
  }

  .modal-container-title {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    font-weight: 400;
    font-size: 1.125;
  }

  .modal-container-body {
    padding: 20px;
    overflow-y: auto;

    .searchInputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      overflow: auto;
      margin: 10px 0;
      width: 100%;

      .inputContainer {
        width: 100%;
        .inputCode {
          display: flex;
          width: 100%;
        }
      }

      label {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.16px;
      }

      input,
      select {
        color: #4367b0;
        width: 500px;
        padding: 15px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.16px;
        border-radius: 22px;
        border: 1px solid #bfbfbf;
        background-color: #fff;
      }

      input {
        width: 100%;
      }

      input::placeholder {
        color: #bfbfbf;
      }

      input:focus {
        outline: 1px solid #4367b0;
      }

      .searchBtnContainer {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .searchBtn {
          border-radius: 36px;
          background-color: #d0d9eb;
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.16px;
          padding: 15px 30px;
          border: none;
        }

        .active {
          background-color: #4367b0;
        }
      }
    }
  }

  .rtf {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
    }

    h1 {
      font-size: 1.5rem;
      line-height: 1.125;
    }

    h2 {
      font-size: 1.25rem;
      line-height: 1.25;
    }

    h3 {
      font-size: 1rem;
      line-height: 1.5;
    }

    & > * + * {
      margin-top: 1em;
    }

    & > * + :is(h1, h2, h3) {
      margin-top: 2em;
    }

    & > :is(h1, h2, h3) + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      margin-left: 20px;
      list-style-position: inside;
    }

    ol {
      list-style: numeric;
    }

    ul {
      list-style: disc;
    }
  }

  .modal-container-footer {
    padding: 20px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #ddd;
    gap: 12px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -51px;
      left: 24px;
      right: 24px;
      height: 50px;
      flex-shrink: 0;
      background-image: linear-gradient(to top, rgba(#fff, 0.75), transparent);
      pointer-events: none;
    }
  }

  .button {
    padding: 12px 20px;
    border-radius: 8px;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    cursor: pointer;
    transition: 0.15s ease;

    &.is-ghost {
      &:hover,
      &:focus {
        background-color: #eae8e7;
      }
    }

    &.is-primary {
      background-color: #4367B0;
      color: #fff;
      &:hover,
      &:focus {
        background-color: #355cab;
      }
    }
  }

  .icon-button {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.15s ease;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
