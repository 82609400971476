@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.ProfitsContainer {
	@include flex(column, 1.5rem, center, center);
	background-color: #ffffff;
	padding: 7rem 8rem 3rem;
	.titleTop {
		@include fontStyle(3.5rem, 300, 3.85rem);
	}
	.mainTitle {
		@include fontStyle(4.75rem, 600, 5.225rem);
	}
	.profitCardsContainer {
		@include flex(row, 1.5rem, start, center);
		flex-wrap: wrap;
		.card {
			background-color: #e1e2e3;
			height: 33.875rem;
			padding: 7%;
			width: 30%;
			border-radius: 1.875rem;
			p {
				@include fontStyle(2.25rem, 400, 2.5rem);
				color: #4267ad;
				span {
					@include fontStyle("", 700, "");
				}
			}
		}
	}
	button {
		@include flex("", 0.5rem, center, center);
		@include fontStyle(1.5rem, 600, 1.125rem);
		border: none;
		border-radius: 0.75rem;
		padding: 1.25rem 2.5rem;
		background-color: #4367b0;
		color: #ffffff;
	}
}

@media (max-width: 1250px) {
	.ProfitsContainer {
		padding: 7rem 6rem 3rem;
	}
}
@media (max-width: 1160px) {
	.ProfitsContainer {
		padding: 7rem 4rem 3rem;
	}
}

@media (max-width: 1024px) {
	.ProfitsContainer {
		text-align: center;
		padding: 7rem 4rem 3rem;
		.profitCardsContainer {
			.card {
				text-align: left;
				padding: 7%;
				width: 48%;
			}
		}
	}
}
@media (max-width: 768px) {
	.ProfitsContainer {
		gap: 1.5rem;
		padding: 3rem 1rem;
		.titleTop {
			@include fontStyle(1.6875rem, "", 1.85rem);
			text-align: center;
		}
		.mainTitle {
			@include fontStyle(2.25rem, "", 2.475rem);
			text-align: center;
		}
		.profitCardsContainer {
			width: 100%;
			flex-direction: column;
			padding: 1rem 0 5rem;
			.card {
				padding: 1.25rem;
				border-radius: 1.25rem;
				height: fit-content;
				width: 20.125rem;
				border-radius: 1.875rem;
				p {
					@include fontStyle(1.6875rem, 400, 2.0625rem);
					text-align: left;
				}
			}
		}
		button {
			@include fontStyle(1rem, 600, 1rem);
			width: 12.375rem;
			height: 3rem;
			padding: 1rem 2.5rem;
			text-align: center;
		}
	}
}
