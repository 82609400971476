.resetPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .loginContainer {
        width: 500px;
        margin: auto;
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        .logo {
            margin: 0 auto;
        }

        .title {
            color: #BFBFBF;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.2px;
        }

        .loginForm {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            label {
                color: #000000;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.2px;

                span {
                    color: #D80032;
                }
            }

            input {
                border-radius: 50px;
                background: #F5F7FA;
                color: var(--Secondary-25, #BFBFBF);
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0.2px;
                border: none;
                width: 100%;
                padding: 24px 60px;
                height: 72px;
            }

            input:focus {
                outline: 1px solid #4367B0;
            }

            .inputUserName,
            .inputPass {
                position: relative;
                width: 100%;
            }

            .emailIcon,
            .passIcon {
                // width: 20x;
                position: absolute;
                left: 25px;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }

            .eyeIcon {
                position: absolute;
                background-color: #fff;
                border-radius: 50%;
                padding: 10px;
                position: absolute;
                right: 25px;
                top: 0;
                bottom: 0;
                margin: auto 0;

            }
        }

        .bottomText {
            color: #000000;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.14px;
        }

        .resetPass {
            color: #4367B0;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
        }

        .loginBtn {
            width: 100%;
            padding: 15px;
            align-items: center;
            border-radius: 36px;
            background: #4367B0;
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.2px;
            border: none;
            position: relative;

            .forwardArrow {
                position: absolute;
                background-color: #fff;
                border-radius: 50%;
                padding: 10px;
                position: absolute;
                right: 25px;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }
        }

        .goBackBtn {
            color: #000;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.2px;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
        }
    }
}