.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 1;
}



.modal-content {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 27%;
    height: 100vh;
    // position: relative;


    .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #BFBFBF;
        padding: 30px 20px;

        .left {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;

            .title {
                color: #000;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0.24px;
            }

        }


        .crossIcon {
            border: 1px solid #BFBFBF;
            border-radius: 50%;
            padding: 5px 15px;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.24px;
        }
    }

    .modalBody {
        padding: 30px;
        display: block;
        // align-items: center;
        justify-content: center;
        // flex-direction: column;
        // gap: 20px;
        overflow-x: hidden;
        overflow-y: auto;
        // height: 100%;
        // max-height: 480px;
        flex: 1;
        padding-bottom: 30px !important;

        .crm-batch-container {
            padding: 24px;
            border-radius: 14px;
            border: 1px solid var(--Secondary-10, #E6E6E6);
            display: flex;
            gap: 10px;
            flex-direction: column;

            p {
                color: var(--Secondary-100, #000);
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.2px;
            }
        }


        .heading {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.24px;
        }

        .notice {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 24px 40px;
            border-radius: 14px;
            background: #FCE6EB;

            p {
                color: #D80032;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.2px;
            }
        }
    }

   

    .goBackBtn {
        border-radius: 36px;
        border: 1px solid #4367B0;
        background: #fff;
        display: flex;
        gap: 10px;
        color: #4367B0;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;
        margin: 0 auto;
        padding: 10px 20px;
    }


}





@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.modal-overlay,
.modal-content {
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

.modal-overlay {
    animation-name: fadeIn;
}

.modal-content {
    animation-name: slideInRight;
}

.modal-overlay.closed {
    animation-name: fadeOut;
}

.modal-content.closed {
    animation-name: slideOutRight;
}


.chatBubble {
    display: flex;
    justify-content: center;
    // margin: 10px 0;
    gap: 15px;
    align-self: flex-end;
    margin-top: 20px;




    img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    .supportBubble {
        margin-right: 85px;
        ;

    }

    .userBubble {
        margin-left: 85px;
        background-color: #4367B0 !important;

        .desc {

            color: #fff !important;
            
        }

        .timeStamp {
            color: #fff !important;
            // display: none;
        }

        .boldBlack {
            // font-weight: bold;
            color: black;
            // display: none;
        }


    }

    .bubble {
        width: 100%;
        min-width: 85%;
        border-radius: 15px;
        background: #F5F5F5;
        padding: 20px;
        text-align: center;
        position: relative;

        .name {
            color: #4367B0;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.2px;
        }

        .boldBlack {
            // font-weight: bold;
            color: black;
        }

        .desc {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.16px;
            text-align: left;
        }

        .timeStamp {
            color: #808080;
            text-align: right;

            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.14px;
        }

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }

    .bubble-blue {
        background-color: #4367B0;
    }

    .bubble-blue .desc {

        color: #fff !important;
    }

    .userBubble:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 15px solid #4367B0;
        border-right: 15px solid transparent;
        border-top: 15px solid #4367B0;
        border-bottom: 15px solid transparent;
        right: -16px;
        top: 0px;

    }

    .supportBubble:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid #F5F5F5;
        border-top: 15px solid #F5F5F5;
        border-bottom: 15px solid transparent;
        left: -16px;
        top: 0px;
    }
}

.chatBubble:first-child {
    margin-top: 0px;
}

.modalFooter {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #BFBFBF;
    // position: absolute;
    // bottom: 0;
    background-color: #fff;
    z-index: 99;


    .msgBox {
        border-radius: 16px;
        border: 1px solid var(--Secondary-25, #BFBFBF);
        background: var(--White-100, #FFF);
        height: 25px;
        padding: 16px 24px;
        width: 100%;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        label {
            color: var(--Secondary-100, #000);
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .sendBtn {
            border-radius: 36px;
            background: var(--Primary-100, #4367B0);
            display: flex;
            width: 110px;
            // height: 25px;
            padding: 8px 10px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border: none;

            color: var(--White-100, #FFF);
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.16px;
        }
    }
}

.checkboxFooter {

    .actions {
        .ticketCheckbox {
            input {
                width: 15px;
                height: 15px;
            }
        }
    }

}