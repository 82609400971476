.Layout {

    .dashboardBody {
        display: flex;
        width: 100%;

        .dashboardContent {
            padding: 20px 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;


            .titleWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .buttonTitleWrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .title {
                    color: #000;
                    font-size: 28px;
                    font-weight: 600;
                    letter-spacing: 0.28px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
 .title {
    font-size: 24px !important;
 }
}