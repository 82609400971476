@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.FooterContainer {
	@include flex(row, "", center, center);
	background-color: #4367b0;
	height: 27.375rem;
	.FooterDetails {
		@include flex(row, 10rem, center, start);
		width: 73%;
		div {
			@include flex(column, 2rem, center, start);
			label {
				@include fontStyle(1rem, 600, 1.5rem);
				color: #ffffff;
			}
			p {
				@include fontStyle(1.0625rem, 400, 1.75rem);
				color: #ffffff;
				width: 17.34375rem;
			}
			.socialMedia {
				@include flex(row, 1rem, center, start);
			}
		}
	}
	.FooterContact {
		@include flex(column, 1.5rem, center, start);
		padding: 3.5rem;
		letter-spacing: 1px;
		.hrLine {
			background-color: #ffffff;
			height: 0.1875rem;
			width: 7.6875rem;
		}
		.contactTitle {
			@include fontStyle(1.75rem, 600, 2.4375rem);
			color: #ffffff;
		}
		.subTitle {
			@include fontStyle(1.0625rem, 400, 1.75rem);
			color: #ffffff;
		}
		.contactDetails {
			@include flex(column, 0.5rem, center, start);

			div {
				@include flex(row, 0.5rem, center, center);
				@include fontStyle(1.125rem, 600, 1.8125rem);
				color: #ffffff;
			}
		}
		height: 100%;
		width: 21.5625rem;
		background-color: #2a3e68;
	}
}
@media (max-width: 1380px) {
	.FooterContainer {
		.FooterDetails {
			@include flex(row, 0, space-between, start);
			padding: 0 1rem;
		}
	}
}
@media (max-width: 1024px) {
	.FooterContainer {
		padding-top: 2rem;
		@include flex(column, 0, space-between, start);
		.FooterDetails {
			width: 100%;
			padding: 2rem;
		}
		.FooterContact {
			@include flex(row, 0, space-between, start);
			width: 100%;
			.hrLine {
				display: none;
			}
		}
	}
}
@media (max-width: 768px) {
	.FooterContainer {
		@include flex(column, "", "", "");
		height: fit-content;
		padding: 3rem 0 0;
		.FooterDetails {
			@include flex(column, 2rem, "", "");
			width: 100%;
			align-items: center;
			padding: 0 1.5rem;
			div {
				@include flex("", 1.5rem, "", center);
				label {
					@include fontStyle(0.9375rem, 600, 1.3125rem);
					text-align: left;
				}
				p {
					@include fontStyle(1rem, 400, 1.546875rem);
					width: 100%;
					letter-spacing: 0.8px;
					text-align: left;
				}
			}
		}
		.FooterContact {
			@include flex(column, 1rem, center, center);
			padding: 2rem;
			margin-top: 2.5rem;
			.hrLine {
				display: block;
				height: 0.125rem;
				width: 7.6875rem;
			}
			.contactTitle {
				font-size: 1.375rem;
			}
			.contactDetails {
				@include flex("", 0, "", center);

				div {
					font-size: 0.875rem;
				}
			}
			height: fit-content;
			width: 100%;
		}
	}
}
