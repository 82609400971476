.addCourse {
    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .courseDetailsContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 40px;

                .courseDetailForm {
                    display: flex;
                    gap: 20px;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .formInput {
                        width: 40%;
                    }

                    label {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.16px;

                        span {
                            color: #D80032;
                        }
                    }

                    input,select {
                        color: #4367B0;
                        width: 100%;
                        padding: 13px 24px;

                        border: 1px solid #BFBFBF;
                        border-radius: 36px;
                        background: #FFF;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.16px;
                        margin-top: 5px;
                    }

                    input::placeholder {
                        color: #BFBFBF;
                    }

                    input:focus {
                        outline: 1px solid #4367B0;
                    }

                    option {
                        text-transform: capitalize;
                    }

                }

                .nextBtn {
                    width: 250px;
                    margin: auto;
                    padding: 15px;
                    align-items: center;
                    border-radius: 36px;
                    background: #4367B0;
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    border: none;
                    position: relative;

                    .forwardArrow {
                        position: absolute;
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 10px;
                        position: absolute;
                        right: 25px;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                    }
                }

            }
        }
    }
    
    .topNavigation {
        display: flex;
        border-radius: 10px;

        .item {
            background-color: #A1B3D8;
            color: var(--White-100, #FFF);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            padding: 20px;
            width: 160px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .active {
            background-color: #4367B0;
        }
    }
}