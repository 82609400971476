
.headerContainer {
   width: 100%;
   display: flex;
   align-items: center;
   gap: 1rem;
   padding: 1rem;
   border-top-left-radius: 1rem;
   border-top-right-radius: 1rem;
   background-color: #eaedf5;
   justify-content: space-between;
}
.heading {
   display: flex;
   flex-direction: column;
}
.headerIcon {
   width: 3rem;
   height: 3rem;
   border-radius: 3rem;
}
.headingTitle {
   font-size: 1.3rem;
   font-weight: 500;
}
.headingSubTitle {
   font-size: 1rem;
   margin-top: 0.6rem;
}
.dashboardHeader {
   width: 100%;
   border: 1px solid #e6e6e6;
   border-radius: 1rem;
}
.headerRightContainer{
   display: flex;
   align-items: center;
   justify-content: center;

}
.headerLeftContainer{
    display: flex;
    gap: 1rem;
    align-items: center;
}