.updateClass {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .topNavigation {
        display: flex;
        border-radius: 10px;
        margin-top: 10px;
        position: relative;

        div:nth-child(1) {
          border-radius: 4px;
          margin: 1px;
          clip-path: polygon(82% 0, 100% 48%, 85% 100%, 0 100%, 0 50%, 0 0);
        }

        div {
          clip-path: polygon(82% 0, 100% 48%, 85% 100%, 0 100%, 16% 49%, 0 0);
        }

        div:nth-child(2) {
          position: absolute;
          left: 125px;
          top: 1px;
        }

        div:nth-child(3) {
          position: absolute;
          left: 255px;
          top: 1px;
        }

        .item {
          background-color: #a1b3d8;
          color: var(--White-100, #fff);
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.16px;
          padding: 20px;
          width: 160px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active {
          background-color: #4367b0;
        }
      }

      .updateClassContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin: 1em 0;

        .searchBar,
        .weekCard {
          display: flex;
          width: 100%;
          gap: 20px;

          .formInput {
            width: 100%;
          }

          label {
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;

            span {
              color: #d80032;
            }
          }

          input,
          select,
          textarea {
            color: black;
            width: 100%;
            padding: 13px 24px;

            border: 1px solid #bfbfbf;
            border-radius: 36px;
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
            margin-top: 5px;
          }

          input::placeholder,
          textarea::placeholder {
            color: #bfbfbf;
          }

          input:focus {
            outline: 1px solid #4367b0;
          }

          .radioBtns {
            display: flex;
            gap: 20px;
            margin-top: 10px;

            label,
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
            }
          }
        }

        .classDetails {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .classHeader {
            display: flex;
            justify-content: space-between;

            div {
              display: flex;
              align-items: center;
              gap: 10px;
            }

            p {
              color: #4367b0;
              font-weight: bold;
              cursor: pointer;
            }
          }

          .vimeoIdContainer {
            display: flex;
            width: 100%;
            gap: 8px;

            .inputContainer {
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              gap: 8px;
            }

            .addBtn {
              background-color: #4367b0;
              color: white;
              padding: 5px 8px;
              border-radius: 5px;
              position: absolute;
              bottom: 14px;
            }

            .deleteBtn {
              color: white;
              background-color: #d80032;
              padding: 4px;
              border-radius: 5px;
            }

            .formInput {
              width: 100%;
            }

            div {
              width: 100%;
            }

            button {
              border: none;
              background-color: transparent;
            }
          }
        }

        .addClassBtn, .collapseAllBtn {
          border-radius: 36px;
          background: var(--Primary-100, #4367b0);
          padding: 10px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          color: var(--White-100, #fff);
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.16px;
          border: none;
        }
        .collapseAllBtn {
          background: #fff;
          border: 1px solid #4367B0;
          color: #4367B0;
        }

        .nextBtn {
          width: 25%;
          margin: auto;
          padding: 10px;
          align-items: center;
          border-radius: 36px;
          background: #4367b0;
          color: #fff;
          text-align: center;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.2px;
          border: none;
          position: relative;
          cursor: pointer;

          button {
            background-color: transparent;
            border: none;
            color: white;
            font-size: 20px;
            margin: 0 8px;
          }
        }

        .navigationBtns {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;

          .prevBtn {
              border-radius: 52px;
              border: 1px solid var(--Primary-100, #4367B0);
              background: var(--White-100, #FFF);
              color: var(--Primary-100, #4367B0);
              text-align: center;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0.2px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              width: 260px;
              height: 56px;
          }

          .nextButton {
              border-radius: 52px;
              background: var(--Primary-100, #4367B0);
              color: var(--White-100, #FFF);
              text-align: center;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0.2px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              border: none;
              width: 260px;
              height: 56px;
          }
      }

      }
    }
  }
}

.weekCard {
  border-radius: 12px;
  border: 1px solid var(--Secondary-25, #bfbfbf);
  padding: 15px 25px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .weekCardTitle,
    .icon {
      color: var(--Secondary-100, #000);
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.16px;
    }
    .icon {
      font-size: 24px;
    }
  }
}
