/** @format */

.dashboardListContainer {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
 }
 .dshboardContent {
    display: flex;
    gap: 2rem;
 }
 .headerChildrenOne {
    display: flex;
    justify-content: space-between;
    padding: 0.9rem;
    border-bottom: 1px solid #e6e6e6;
 }
 .headerChildrenTwo {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
 }
 .noBorder {
    border: none;
 }
 .noDataText {
    font-size: 1.3rem;
    font-weight: 600;
 }
 .courseCardWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
    padding: 10px;
 }
 .dashboardHomeWorkSubtitles{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
 }
 
 .dashboardHomeWorkContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    .dashboardHomeWorkInput{
        width: 500px;
    }
 
    .dashboardHomeWorkQuestionContainer{
       .dashboardHomeWorkTitle{
          font-size: 18px;
          font-weight: 500;
       }
    }
 
    .dashboardNotesLink{
       text-decoration: underline;
       color: #4367B0;
    }
 
    .homeWorkUrlWrapper{
       display: flex;
       flex-direction: column;
       gap: 10px;
    }
 }