.videoRequest {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .videoRequestContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        gap: 40px;
        overflow-x: auto;
        margin-top: 10px;

        .videoRequestTable {
          width: 100%;
          overflow-x: auto;

          table {
            width: 100%;
            border-radius: 14px;
            border: 1px solid var(--Secondary-10, #e6e6e6);
          }

          th {
            border-bottom: 1px solid #bfbfbf;
            color: #000000;
            // color: var(--Secondary-50, #808080);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            text-align: left;
            padding: 10px;

            span {
              font-size: 14px;
            }
          }

          tr td {
            padding: 10px;
            font-size: 14px;
            color: var(--Secondary-50, #4c4a4a);
          }

          .requestBtn {
            cursor: pointer;
            color: white;
            padding: 10px;
            background-color: #4367b0;
            border: none;
            border-radius: 4px;
          }

          .approvedBtn {
            cursor: pointer;
            color: white;
            padding: 10px;
            background-color: #00a65a;
            border: none;
            border-radius: 4px;
          }

          .deniedBtn {
            cursor: pointer;
            color: white;
            padding: 10px;
            background-color: #D80032;
            border: none;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
