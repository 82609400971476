@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.RegistrationContainer {
	@include flex("", 0, center, center);
	form {
		@include flex(row, 0, space-between, center);
		flex-wrap: wrap;
		padding: 1.5rem;
		margin: 3rem 0;
		border-radius: 0.9375rem;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 0.0625rem 0.25rem;
		width: 80%;
		h1 {
			color: #4367b0;
			width: 100%;
			text-align: center;
			margin: 1rem 0;
		}
		.locationfield {
			width: 49%;
		}
		.InputFields {
			width: 49%;
			margin-top: 1.5rem;
			label {
				span {
					color: red;
				}
			}
			input,
			select {
				margin-top: 0.3rem;
				border-radius: 0;
			}
			.optionsInputFields {
				@include flex(row, 5rem, start, center);
				padding: 1rem;
				.optionsContainer {
					@include flex(row, 1rem, space-between, center);
				}
			}
		}
		.locationfield {
			width: 49%;
			margin-right: 10rem;
		}
		.optionsInputFieldsContainer {
			@include flex(row, 1.5rem, start, center);
		}
		.submitBtnContainer {
			@include flex(row, 0, center, center);
			width: 100%;
			button.Submit {
				@include flex("", 0.5rem, center, center);
				@include fontStyle(0.875rem, 600, 0.7225rem);
				border: none;
				margin-top: 2rem;
				padding: 0.9375rem 3.125rem;
				border-radius: 0.541875rem;
				background-color: #4367b0;
				color: #ffffff;
			}
		}
		.newMemberContainer {
			@include flex(column, 1.5rem, center, center);
			padding: 1.5rem;
			width: 100%;
			margin-top: 1.5rem;
			border: 0.0625rem solid #bfbfbf;
			.newMember {
				@include flex(column, 0, space-between, start);
				padding: 1rem;
				width: 100%;
				box-shadow: rgba(0, 0, 0, 0.02) 0px 0.0625rem 0.1875rem 0px,
					rgba(27, 31, 35, 0.15) 0px 0px 0px 0.0625rem;
				.newMemberInputFields {
					@include flex(row, 1.5rem, space-between, center);
					width: 100%;
					.InputFields {
						width: 100%;
					}
				}
				.newMemberFunBtns {
					@include flex(row, 2rem, end, center);
					width: 100%;
					margin-top: 0.5rem;
					button {
						@include flex("", 0.5rem, center, center);
						box-shadow: rgba(0, 0, 0, 0.16) 0px 0.0625rem 0.25rem;
						border-radius: 0.3125rem;
						border: none;
						@include fontStyle(0.8rem, 600, 1.125rem);
						color: #ffffff;
						padding: 0.5rem 0.5rem;
						img {
							height: 1rem;
							width: 1rem;
						}
					}
					.newMemberAddBtn {
						background-color: #658fe3;
					}
					.newMemberDelBtn {
						padding: 0.4rem 1rem;
						background-color: rgb(254, 233, 233);
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.RegistrationContainer {
		form {
			@include flex(column, 0, space-between, center);
			margin: 0.6rem 0;
			border-radius: 1rem;
			width: 94%;
			.locationfield {
				margin: 0;
			}
			.InputFields {
				width: 100%;
				.optionsInputFields {
					@include flex(row, 2.3rem, start, center);
					padding: 0;
					.optionsContainer {
						@include flex(row, 0.8rem, space-between, center);
					}
				}
			}

			.optionsInputFieldsContainer {
				@include flex(column, 0.5rem, start, start);
			}
			.submitBtnContainer {
				width: 100%;
				button.Submit {
					width: 10rem;
					height: 2.5rem;
					padding: 0.5375rem 1.349375rem;
					border-radius: 0.405rem;
				}
			}
			.newMemberContainer {
				padding: 0.5rem;
				.newMember {
					padding: 0.5rem;
					.newMemberInputFields {
						@include flex(column, 0, space-between, center);
					}
					.newMemberFunBtns {
						@include flex(row, 0.5rem, end, center);
						margin-top: 1rem;
						button {
							@include fontStyle(0.8125rem, 600, 1.125rem);
							padding: 0.3rem 1rem;
							img {
								height: 0.9375rem;
								width: 0.9375rem;
							}
						}
					}
				}
			}
		}
	}
}
