.studentTicketContainer {
    width: 100%;
    border-radius: 14px;
    border: 1px solid #E6E6E6;
    background-color: #FFF;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .studentTicketFormGroup {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .subjectName {
            width: 65%;
        }

        .courseName {
            width: 30%;
        }
    }
}