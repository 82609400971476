.registartionForm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    background-color: #E3EDFD;
    min-height: 100vh;


    .registartionFormContainer {
        height: 100%;
        width: 500px;
        margin: 0 auto;
        padding: 25px;
        background-color: #fff;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        gap: 20px;
        border-top: 5px solid #4367B0;

        .nextBtn {
            padding: 10px 35px;
            align-items: center;
            border-radius: 36px;
            background: #4367B0;
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.2px;
            border: none;
            justify-content: center;
            display: flex;
            gap: 10px;
            margin-top: 10px;
        }

        hr {
        background-color: #efefef;
        border: none;
        height: 2px;
        }

        .inputFields {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .info {
            font-weight: 600;
            opacity: 80%;
        }

        .formInput {
            width: 100%;

            label {
                color: #000000;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
    
                span {
                    color: #D80032;
                }
            }
    
            input,
            select {
                color: #000;
                width: 100%;
                padding: 13px 24px;
    
                border: 1px solid #BFBFBF;
                border-radius: 36px;
                background: #FFF;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.16px;
                margin-top: 5px;
            }
    
            input::placeholder {
                color: #BFBFBF;
            }
    
            input:focus {
                outline: 1px solid #4367B0;
            }
        }

      

        .uploadArea {
            width: 100%;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #BFBFBF;
            background-color: #FFF;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .titleHomework {
                border-radius: 8px;
                background: var(--Secondary-100, #000);
                padding: 10px;

                color: var(--White-100, #FFF);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
            }

            .uploadInfo {
                color: var(--Secondary-100, #000);
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }

            .uploadHomeworkBtn {
                border-radius: 8px;
                background: var(--Primary-100, #4367B0);
                padding: 8px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                color: var(--White-100, #FFF);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
                border: none;
                width: 130px;
                margin: 0 auto;
            }

            .uploadContainer {
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 15px;
            }

            .fileNames {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 10px;

                .fileName {
                    color: var(--Primary-100, #4367B0);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16px;
                    text-decoration-line: underline;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    .removeBtn {
                        color: var(--Error-100, #D80032);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .webcamContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
    .webcam {
        width: 100%;
        border-radius: 8px;
    }



    .captureBtn {
        border: 1px solid #4367B0;
        background-color: #fff;
        color: #4367B0;
        border-radius: 10px;
        padding: 10px 30px;
        margin: 0 auto;
    }

    @media only screen and (max-width: 600px) {
        .registartionFormContainer {
            width: 90%;
        }
    }
}