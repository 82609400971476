 .TableContainer {
     width: 100%;
     border-radius: 14px;
     border: 1px solid #E6E6E6;
     background-color: #FFF;
     padding: 25px;
     display: flex;
     flex-direction: column;
     gap: 20px;

     .Table {
         table {
             border-collapse: collapse;
             border-spacing: 0;
             width: 100%;
             
         }

         tbody{
            position: relative;
        
            .NoDataFoundWrapper{
                position: absolute;
                width: 100%;    
                height: 99%;
            }
         }

         tr th {
             color: #000000;
             font-size: 16px;
             font-weight: 500;
             letter-spacing: 0.16px;
         }

         tr td {
             color: #000;
             font-size: 16px;
             font-weight: 400;
             letter-spacing: 0.16px;
         }

         th,
         td {
             text-align: left;
             padding: 18px 8px 18px 8px;
             

         }

         tr {
             border-bottom: 1px solid #BFBFBF;

         }

         //   tr:nth-child(even){background-color: #f2f2f2}
     }

 }