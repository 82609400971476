.studentTerms {

    .dashboardBody {
        display: flex;

        .dashboardContent {
            padding: 20px 15px;
             width: 100%;

            .title {
                color: #000;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 0.28px;
            }

            .courseListContainer {
                border-radius: 14px;
                border: 1px solid #E6E6E6;
                background-color: #FFF;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 20px;


            }
        }
    }

  
}