.studentClassListContainer {
    width: 100%;
    border-radius: 14px;
    border: 1px solid #E6E6E6;
    background-color: #FFF;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;

   .warningText{
    color: #D80032;
   }

   .CRMWrapper {
       display: flex;
       flex-direction: column;
       gap: 10px;

       .crmNameWrapper {
           display: flex;
           align-items: center;
           gap: 10px;

           .crmIconWrapper {
               display: flex;
               align-items: center;
           }
       }
   }

   .classVideosMessageWrapper{
    display: flex;
    flex-direction: column;
    gap: 2px;

    .classVideosWrapper{
        display: flex;
        align-items: center;
        gap: 2px;
    }
   
   }

   .batchTableBtn {
    // color: #fff;
    font-size: 16px;
    font-weight: 500;
    background-color: #00a65a;
    // border-radius: 8px;
    // padding: 0 10px;
    // display: flex;
    align-items: center;
    justify-content: center;
    border-color: #00a65a;
    // gap: 5px;
  }

   .youtubeIcon {
    background-color: #4367B0;
    height: 36px;
    width: 36px;
    padding: 5px;
    border-radius: 8px;
   }
}

@media only screen and (max-width: 1400px) {
    .title {
       font-size: 24px !important;
    }

    tr,td,th {
        font-size: 14px !important;
    }
   }