.crmAdminHomework {
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 28px;
				font-weight: 600;
				letter-spacing: 0.28px;
			}

			.batchListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				max-height: 95%;

				.uploadHomeworkBtn {
					border-radius: 8px;
					background: var(--Primary-100, #4367b0);
					padding: 8px 12px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;

					color: var(--White-100, #fff);
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
					border: none;
					width: 130px;
					margin: 0 auto;
				}
				.uploadBtnText {
					color: #4367b0;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
					text-decoration: underline;
					background: transparent;
					border: none;
				}

				.topSearchBar {
					.searchInputs {
						display: flex;
						gap: 20px;
						align-items: flex-end;
						overflow: auto;

						label {
							color: #000000;
							font-weight: 500;
							font-size: 16px;
							letter-spacing: 0.16px;
						}

						input,
						select {
							color: #4367b0;
							// width: 470px;
							padding: 15px;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 0.16px;
							border-radius: 22px;
							border: 1px solid #bfbfbf;
							background-color: #fff;
						}

						select {
							width: 150px;
						}

						input::placeholder {
							color: #bfbfbf;
						}

						input:focus {
							outline: 1px solid #4367b0;
						}

						.inputContainer {
							// width: 20%;
						}

						.searchBtnContainer {
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;

							.searchBtn {
								border-radius: 36px;
								background-color: #d0d9eb;
								color: #fff;
								text-align: center;
								font-size: 16px;
								font-weight: 500;
								letter-spacing: 0.16px;
								padding: 15px 30px;
								border: none;
							}

							.active {
								background-color: #4367b0;
							}
						}
					}
				}

				.courses {
					table {
						border-collapse: collapse;
						border-spacing: 0;
						width: 100%;
						// table-layout: fixed;
					}

					tr th {
						color: #000000;
						font-size: 16px;
						font-weight: 700;
						letter-spacing: 0.16px;
						// width: 150px;
						white-space: nowrap;
					}

					tr td {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
						white-space: nowrap;
					}
					thead tr td {
						border: 1px solid #bfbfbf;
					}
					tbody tr td {
						border: 1px solid #eee;
					}

					th,
					td {
						text-align: left;
						padding: 8px;
					}

					tr {
						border-bottom: 1px solid #bfbfbf;
					}
					tbody tr:nth-child(even) {
						background-color: #eeeeee4d;
					}

					.viewBtn {
						color: #fff;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						background-color: #4367b0;
						border-radius: 8px;
						padding: 0 10px;
						height: 45px;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 5px;
						border: none;
					}

					.questionNo {
						span {
							border-radius: 6px;
							background: var(--Primary-100, #4367b0);
							display: flex;
							width: 36px;
							height: 36px;
							justify-content: center;
							align-items: center;

							color: var(--White-100, #fff);
							text-align: center;
							font-size: 16px;
							font-weight: 600;
							letter-spacing: 0.16px;
						}
					}

					//   tr:nth-child(even){background-color: #f2f2f2}
				}
			}
		}
	}

	.actionBtn {
		// width: 200px;
		// display: flex;
		gap: 10px;
	}

	.editBtn {
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.16px;

		background-color: #4367b0;
		border-radius: 8px;
		padding: 0 10px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		border: none;
	}

	.homeworkBtn,
	.materialBtn {
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.16px;
		background-color: #4367b0;
		border-radius: 8px;
		padding: 0 10px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pagination {
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
		margin: 30px auto;

		button {
			border-radius: 50%;
			border: 1px solid #e6e6e6;
			background: #fff;
			// padding: 10px 20px;
			height: 45px;
			width: 45px;
			color: #000;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
		}

		button:disabled {
			background-color: #eee;
			color: gray;
		}

		.active {
			border-radius: 6px;
			background: #000;
			color: #fff;
		}
	}

	.myHomeworkTable {
		max-height: 650px;
		overflow-y: auto;
		border: 1px solid #bfbfbf;
		// thead .stickyheader {
		//     position: sticky;
		//     top: 0;
		// }

		.centeredText {
			th {
				text-align: center !important;
			}
		}

		table {
			width: 100%;
			border-collapse: separate !important;
			border-spacing: 0 !important;
		}

		tr th {
			color: #000000;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.16px;
			// background-color: red;
			white-space: nowrap;
			border: 1px solid #bfbfbf;
			border-collapse: collapse;
			white-space: nowrap;
		}

		tr td {
			display: flexbox;
			color: #000;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
			white-space: nowrap;
			// border: 1px solid #bfbfbf;
			white-space: nowrap;
		}

		th,
		td {
			text-align: left;
			padding: 8px;
			white-space: nowrap;
		}

		tr {
			// border-bottom: 1px solid #bfbfbf;
		}

		.stickyColumn {
			select {
				min-width: 140px;
				padding: 5px;
				option {
					background: #ffffff;
				}
			}
			input {
				min-width: 150px !important;
			}
			th {
				z-index: 99 !important;
			}
			td:nth-child(1),
			th:nth-child(1) {
				position: sticky;
				left: 0;
				min-width: 150px;
				background-color: #eee;
				// z-index: 10;
				border: 1px solid #bfbfbf !important;
			}

			td:nth-child(2),
			th:nth-child(2) {
				position: sticky;
				background-color: #eee;
				min-width: 150px;
				left: 150px;
				// z-index: 10;
				border: 1px solid #bfbfbf !important;
			}

			td:nth-child(3),
			th:nth-child(3) {
				position: sticky;
				background-color: #eee;
				min-width: 150px;
				left: 300px;
				// z-index: 10;
				border: 1px solid #bfbfbf !important;
			}
		}

		// th[colspan="7"],
		// td[colspan="7"] {
		//     width: auto;
		//     min-width: 100px;
		// }
	}

	.fullScreenConatiner {
		display: flex;
		margin: 20px 0;
		justify-content: flex-end;
		.fullScreenAction {
			display: flex;
			gap: 10px;
			align-items: center;
			color: #4367b0;
			font-size: 16px;
			font-weight: 500;
			margin-left: auto;
			cursor: pointer;

			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}
	}
	.fullScreenActive {
		height: 100%;
		display: flex;
		background-color: #fff;
		flex-direction: column;
		padding: 20px;
		// justify-content: center;
	}
}
