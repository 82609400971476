.paymentUpdate {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .batchStudentListContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px 0;


      
       
          }
        }

      


        .row {
          display: flex;
          flex-direction: column;
          gap: 10px;


          .firstTitle {
            color: var(--Secondary-100, #000);
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.2px;
          }
        }

        .first {
          align-items: center;
          justify-content: center;
        }


      .downloadBtn {
        button {
          color: var(--White-100, #FFF);
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.16px;
          border-radius: 6px;
          background: var(--Primary-100, #4367B0);
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border: none;
        }
      }
  }

}