.sideBarContainer {
    height: calc(100vh - 70px);
    background-color: #fff;
    position: relative;
    width: 230px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    position: relative;


    .headerDropdown {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        select {
            border: none;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.32px;
        }

        select:focus {
            outline: none;
        }
    }

    .menuItemList {
        display: flex;
        flex-direction: column;
        gap: 15px;
        flex: 1;
        max-height: 450px;
        overflow: auto;

        .menuItem {
            width: 100%;
            cursor: pointer;

            .menuBox {
                padding: 5px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                .iconText {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;

                    .menuTitle {
                        color: #000000;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0.28px;
                    }
                }


            }



        }

        .subMenus {
            display: flex;
            flex-direction: column;
            padding-left: 23px;

            p {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
                padding: 10px 20px;

            }
        }

        .active {
            background-color: #FEF5F7;

            .dropdownIndicator {
                transform: rotate(90deg);
            }
        }

        .active::before {
            content: " ";
            border-radius: 0px 2px 2px 0px;
            background: #ED1414;
            width: 4px;
            height: 24px;

            position: absolute;
            left: 0;

        }
    }



    .sidebarFooter {
        border-top: 1px solid var(--Secondary-25, #BFBFBF);
        padding: 25px 15px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 100%;

        .avatarContainer {
            display: flex;
            gap: 20px;


            .avatar {
                position: relative;

                img {
                    width: 46px;
                    height: 46px;
                    border-radius: 100%;
                    position: relative;
                }

                span {
                    display: block;
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background-color: #36C236;
                }



            }

            .details {
                display: flex;
                flex-direction: column;
                gap: 5px;

                p {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

        }

        .logoutBox {
            display: flex;
            align-items: center;
            gap: 20px;
            cursor: pointer;

            p {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
            }
        }
    }


}