.attendanceList {
	position: relative;

	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;

			.title {
				color: #000;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 0.28px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.buttonContainer {
					display: flex;
					gap: 5px;
				}

				.downloadBtn {
					color: #fff;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
					border: none;
					background-color: #4367b0;
					border-radius: 25px;
					padding: 0 10px;
					height: 45px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					img {
						height: 20px;
						width: 20px;
					}
				}
			}

			.attendanceListContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin: 20px 0;

				.topSearchBar {
					margin: 15px 0;
					overflow: auto;

					.searchInputs {
						display: flex;
						gap: 20px;
						align-items: flex-end;
						margin: 10px 0;

						.searchField {
							position: relative;
							z-index: 20;

							.searchDropdown {
								position: absolute;
								top: 70px;
								width: 100%;
								background-color: #fff;
								border-radius: 8px;
								// border: 1px solid #000;
								z-index: 20;
								box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
								overflow: auto;

								p {
									cursor: pointer;
									padding: 5px 10px;
									font-size: 14px;
								}

								p:hover {
									background-color: #e6e6e6;
								}
							}
						}

						label {
							color: #000000;
							font-weight: 500;
							font-size: 16px;
							letter-spacing: 0.16px;
						}

						input,
						select {
							color: #4367b0;
							padding: 15px;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: 0.16px;
							border-radius: 22px;
							border: 1px solid #bfbfbf;
							background-color: #fff;
						}

						select {
							width: 150px;
						}

						input::placeholder {
							color: #bfbfbf;
						}

						input:focus {
							outline: 1px solid #4367b0;
						}

						.searchBtnContainer {
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;

							.searchBtn {
								border-radius: 36px;
								background-color: #d0d9eb;
								color: #fff;
								text-align: center;
								font-size: 16px;
								font-weight: 500;
								letter-spacing: 0.16px;
								padding: 15px 30px;
								border: none;
							}

							.active {
								background-color: #4367b0;
							}
						}
					}
				}

				.courses {
					overflow-x: auto;
					margin: 10px 0;
					table {
						border-collapse: collapse;
						border-spacing: 0;
					}

					tr th {
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
					}

					tr td {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
					}

					th,
					td {
						text-align: left;
						padding: 8px;
					}

					tr {
						border-bottom: 1px solid #bfbfbf;
					}

					//   tr:nth-child(even){background-color: #f2f2f2}
				}
			}
			.paginationWrapper {
				position: "absolute";
				bottom: 20px;
				right: 20px;
			}
		}
	}

	.actionBtn {
		// width: 200px;
		display: flex;
		gap: 10px;
	}

	.pagination {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		align-items: center;
		margin: 30px auto;

		button {
			border-radius: 50%;
			border: 1px solid #e6e6e6;
			background: #fff;
			height: 45px;
			width: 45px;
			color: #000;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.16px;
		}
		button:disabled {
			background-color: #eee;
			color: gray;
		}

		.active {
			border-radius: 6px;
			background: #000;
			color: #fff;
		}

		select {
			border: 1px #f5f5f5;
			border-radius: 10px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			padding: 10px;
		}
	}
}

.batchTableBtn {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	background-color: #00a65a;
	border-radius: 8px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.resetBtn {
	border-radius: 36px;
	background-color: #ed8aa1;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.16px;
	padding: 15px 30px;
	border: none;
}

.attendanceTable {
	width: 100%;
	height: 4rem;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}

	tr {
		margin: 8px 0;
	}

	tr th {
		color: #000000;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0.16px;
	}

	tr td {
		display: flexbox;
		color: #000;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.16px;
	}

	th,
	td {
		text-align: left;
		padding: 8px;
	}

	tr {
		border-bottom: 1px solid #bfbfbf;
	}

	tr th:nth-child(7) {
		text-align: center;
	}

	tr th:nth-child(8) {
		text-align: center;
	}

	td a {
		color: white;
	}
}

.presentBtn {
	border: none;
	padding: 10px;
	border-radius: 8px;
	margin: 0 2px;
	background-color: #00a65a;
	font-size: 14px;
	color: white;
}

.absentBtn {
	border: none;
	padding: 10px;
	border-radius: 8px;
	margin: 0 2px;
	background-color: #d80032;
	font-size: 14px;
	color: white;
}
