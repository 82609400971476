@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.AlumniContainer {
	@include flex(column, 1rem, center, center);
	padding: 4rem 8rem;
	letter-spacing: 0.06875rem;
	overflow: hidden;

	label {
		@include fontStyle(1rem, 600, 1.5rem);
		background-color: #ebf1ff;
		border-radius: 1.5rem;
		padding: 0.25rem 1rem 0.125rem;
		color: #4367b0;
	}
	h2 {
		@include fontStyle(3rem, 600, 3.6rem);
	}
	.reviewsContainer {
		@include flex(column, 2rem, center, center);
		position: relative;
		width: 100%;
		padding: 1rem;
		.reviewCard {
			@include flex(row, 2rem, center, start);
			padding: 2rem;
			position: relative;
			width: 90%;
			border-radius: 10px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			div.leftAlumni {
				width: 60%;
				ul.review {
					width: 100%;
					@include fontStyle(1.3rem, 400, 2.5rem);
					text-align: left;
					font-style: italic;
					li {
						color: rgb(35, 36, 38);
					}
					// div.aluLogoContainer {
					// 	@include flex(row, "", end, center);
					// 	width: 100%;
					// 	height: 3.125rem;
					// 	img {
					// 		object-fit: contain;
					// 		place-self: right;
					// 		height: 3.125rem;
					// 		width: 6.25rem;
					// 	}
					// }
				}
				div.aluLogoContainer {
					height: 3.125rem;
					position: absolute;
					bottom: 1rem;
					right: 1.5rem;
					img {
						object-fit: contain;
						height: 3.125rem;
						width: 6.25rem;
					}
				}
			}
			div.ReviewCardcontents {
				@include flex(column, 0, center, center);
				width: 40%;
				img {
					height: 100px;
					width: 100px;
					border-radius: 50%;
				}
				p.name {
					@include fontStyle(1.5rem, 700, 2.25rem);
					text-align: center;
				}
				p.aluCompany {
					@include fontStyle(1.2rem, 500, "");
					text-align: center;
				}
				p.aluPosition {
					@include fontStyle(1rem, 600, "");
					text-align: center;
					color: #4367b0;
				}
			}
		}
	}
	button {
		@include flex("", 0.5rem, center, center);
		@include fontStyle(1.5rem, 600, 1.125rem);
		border: none;
		border-radius: 0.75rem;
		padding: 1.25rem 2.5rem;
		background-color: #4367b0;
		color: #ffffff;
	}
}
@media (max-width: 1250px) {
	.AlumniContainer {
		padding: 4rem 6rem;
	}
}
@media (max-width: 1160px) {
	.AlumniContainer {
		padding: 3rem 0;
		.reviewsContainer {
			margin-top: 1rem;
			padding: 0;
			.reviewCard {
				padding: 1rem;
			}
		}
	}
}

@media (max-width: 768px) {
	.AlumniContainer {
		padding: 2rem 0.5rem 3rem;
		label {
			width: fit-content;
		}
		h2 {
			@include fontStyle(2rem, 600, 2.4rem);
			text-align: center;
		}
		.reviewsContainer {
			@include flex(column, 1rem, center, center);
			padding: 0;
			.reviewCard {
				width: 100%;
				@include flex(row, 1rem, space-between, start);
				position: relative;
				div.leftAlumni {
					width: 60%;
					ul.review {
						width: 100%;
						@include fontStyle(0.75rem, 400, 1.125rem);
						font-style: italic;
						text-align: left;
						li {
							color: rgb(35, 36, 38);
						}
					}
					div.aluLogoContainer {
						height: 1.125rem;
						position: absolute;
						bottom: 0.5rem;
						right: 0.5rem;
						img {
							object-fit: contain;
							height: 1.125rem;
							width: 3.25rem;
						}
					}
				}
				div.ReviewCardcontents {
					padding-right: 1rem;
					img {
						height: 50px;
						width: 50px;
					}

					p.name {
						@include fontStyle(0.75rem, 700, 1rem);
						margin-top: 0.5rem;
					}
					p.aluCompany {
						@include fontStyle(0.75rem, 500, "");
					}
					p.aluPosition {
						@include fontStyle(0.75rem, 600, "");
					}
				}
			}
		}
		button {
			@include fontStyle(1rem, 600, 1rem);
			border: none;
			padding: 1rem 2.5rem;
			text-align: center;
		}
	}
}
