@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.BusinessesStatsContainer {
	@include flex(column, 1.5rem, center, center);
	background-color: #4267ad;
	padding: 7rem 8rem;
	.titleTop {
		@include fontStyle(3.5rem, 300, 3.85rem);
		color: #ffffff;
	}
	.mainTitle {
		@include fontStyle(4.75rem, 600, 5.225rem);
		color: #ffffff;
	}
	.subStatsContainer {
		@include flex(row, 2rem, center, start);
		width: 100%;
		.subStats {
			@include flex(column, 1.5rem, center, center);
			margin-top: 1.5rem;
			label {
				@include fontStyle(1.6875rem, 700, 1.6875rem);
				color: #ffffff;
			}
			p {
				@include fontStyle(1.25rem, 400, 1.125rem);
				text-align: center;
				width: 13rem;
				color: #ffffff;
			}
		}
	}
}
@media (max-width: 1350px) {
	.BusinessesStatsContainer {
		padding: 7rem 6rem;
	}
}
@media (max-width: 1250px) {
	.BusinessesStatsContainer {
		padding: 7rem 4rem;
		.subStatsContainer {
			flex-wrap: wrap;
		}
	}
}
@media (max-width: 1024px) {
	.BusinessesStatsContainer {
		gap: 1.4rem;
		padding: 3.5rem 2rem;
		.titleTop {
			@include fontStyle(2.6875rem, 300, 1.85625rem);
		}
		.mainTitle {
			@include fontStyle(3.25rem, 600, 2.475rem);
			text-align: center;
		}
		.subStatsContainer {
			flex-wrap: wrap;
		}
	}
}
@media (max-width: 768px) {
	.BusinessesStatsContainer {
		.titleTop {
			@include fontStyle(1.6875rem, 300, 1.85625rem);
		}
		.mainTitle {
			@include fontStyle(2.25rem, 600, 2.475rem);
			text-align: center;
		}
		.subStatsContainer {
			@include flex(column, 2rem, center, center);
			flex-wrap: nowrap;
			.subStats {
				label {
					@include fontStyle(2.25rem, 700, 2.25rem);
					text-align: left;
				}
				p {
					@include fontStyle(1.125rem, 400, 1.125rem);
					width: 100%;
					text-align: center;
				}
			}
		}
	}
}
