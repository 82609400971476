.companyDetails {
  .dashboardBody {
    display: flex;

    .dashboardContent {
      padding: 20px 15px;
       width: 100%;

      .title {
        color: #000;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.28px;
      }

      .companyDetailsContainer {
        border-radius: 14px;
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 10px;

        .companyDetailsForm {
          display: flex;
          gap: 30px;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;

          .formInput {
            width: 100%;
          }

          label {
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;

            span {
              color: #d80032;
            }
          }

          input,
          select,
          textarea {
            color: black;
            width: 100%;
            padding: 13px 24px;

            border: 1px solid #bfbfbf;
            border-radius: 36px;
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
            margin-top: 5px;
          }

          input::placeholder, textarea::placeholder {
            color: #bfbfbf;
          }

          input:focus {
            outline: 1px solid #4367b0;
          }

          .saveBtn {
            background-color: #4367b0;
            color: white;
            padding: 14px;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
