.addWorkshop {
	.dashboardBody {
		display: flex;

		.dashboardContent {
			padding: 20px 15px;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 20px;

			.title {
				color: #000;
				font-size: 28px;
				font-weight: 600;
				letter-spacing: 0.28px;
			}

			.courseDetailsContainer {
				border-radius: 14px;
				border: 1px solid #e6e6e6;
				background-color: #fff;
				padding: 25px;
				display: flex;
				flex-direction: column;
				gap: 40px;

				.courseDetailForm,
				.formLower {
					display: flex;
					gap: 20px;
					width: 100%;
					flex-wrap: wrap;
					justify-content: space-between;

					.dateTime {
						width: 45%;
						display: flex;
						justify-content: space-between;
						gap: 20px;

						.formInput {
							width: 50%;
						}
					}

					.memberType {
						width: 45%;
						display: flex;
						flex-direction: column;
						gap: 20px;

						.formInput {
							width: 100%;
						}
					}

					.memberRadio {
						margin-top: 10px;
						display: flex;
						gap: 30px;

						label {
							display: flex;
							gap: 10px;
							align-items: flex-start;
							justify-content: flex-start;
						}

						input {
							width: 20px;
						}
					}

					.formInput {
						width: 45%;
						.selectedBatches {
							display: flex;
							justify-content: start;
							align-items: center;
							flex-direction: row;
							gap: 0.5rem;
							padding: 0.5rem;
							flex-wrap: wrap;
							button.selectedBatch {
								display: flex;
								justify-content: space-between;
								align-items: center;
								gap: 1rem;
								width: fit-content;
								padding: 5px 10px;
								border: 1px solid #92ee84;
								border-radius: 36px;
								background: transparent;
							}
						}
					}

					label {
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;

						span {
							color: #d80032;
						}
					}

					input,
					select {
						color: #000;
						width: 100%;
						padding: 13px 24px;

						border: 1px solid #bfbfbf;
						border-radius: 36px;
						background: #fff;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.16px;
						margin-top: 5px;
					}

					input::placeholder {
						color: #bfbfbf;
					}

					input:focus {
						outline: 1px solid #4367b0;
					}
				}

				.formLower {
					width: 50%;
					display: flex;
					flex-direction: column;
					gap: 20px;

					.formInput {
						width: 100%;
					}
				}

				.footerNavBtns {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 20px;

					.backBtn {
						width: 260px;
						padding: 15px;
						align-items: center;
						border-radius: 36px;
						background: #fff;
						border: 1px solid #4367b0;
						color: #4367b0;
						text-align: center;
						font-size: 20px;
						font-weight: 500;
						letter-spacing: 0.2px;
						justify-content: center;
						display: flex;
						gap: 10px;
					}
					.nextBtn {
						width: 260px;
						padding: 15px;
						align-items: center;
						border-radius: 36px;
						background: #4367b0;
						color: #fff;
						text-align: center;
						font-size: 20px;
						font-weight: 500;
						letter-spacing: 0.2px;
						border: none;
						justify-content: center;
						display: flex;
						gap: 10px;

						// .forwardArrow {
						//     position: absolute;
						//     background-color: #fff;
						//     border-radius: 50%;
						//     padding: 10px;
						//     position: absolute;
						//     right: 25px;
						//     top: 0;
						//     bottom: 0;
						//     margin: auto 0;
						// }
					}
				}
			}

			.uploadArea {
				width: 350px !important;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #bfbfbf;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				gap: 10px;

				.titleHomework {
					border-radius: 8px;
					background: var(--Secondary-100, #000);
					padding: 10px;

					color: var(--White-100, #fff);
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
				}

				.uploadInfo {
					color: var(--Secondary-100, #000);
					text-align: center;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					letter-spacing: 0.12px;
				}

				.uploadHomeworkBtn {
					border-radius: 8px;
					background: var(--Primary-100, #4367b0);
					padding: 8px 12px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;

					color: var(--White-100, #fff);
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.16px;
					border: none;
					width: 130px;
					margin: 0 auto;
				}

				.uploadContainer {
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					gap: 15px;
				}

				.fileNames {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					gap: 10px;

					.fileName {
						color: var(--Primary-100, #4367b0);
						text-align: center;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0.16px;
						text-decoration-line: underline;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 20px;

						.removeBtn {
							color: var(--Error-100, #d80032);
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 10px;
							text-decoration: none;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
