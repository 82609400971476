@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.NavContainer {
	@include flex("", "", space-between, center);
	padding: 0 8rem;
	height: 7.1rem;
	border-bottom: 1px solid #e2e4e9;

	.navItems {
		div.shadow,
		div.itemLeft {
			@include flex("", 2rem, center, center);
			letter-spacing: 0.1rem;
			.mobileCrossBtnContainer {
				display: none;
				button.mobile-menu-icon {
					background: none;
					border: none;
					cursor: pointer;
				}
			}
			a {
				color: black;
				text-decoration: none;
				font-weight: bold;
			}

			button.BMPbtn {
				border: 2px solid #4367b0;
				border-radius: 10px;
				letter-spacing: 0.06rem;
				font-weight: bold;
				background-color: transparent;
				padding: 0.875rem 1.875rem;
				margin: 0 1rem 0 2rem;
				box-shadow: 0 0.25rem 1rem rgba(69.7, 69.7, 69.7, 0.2);
			}
		}
	}

	.mobileBtnContainer {
		display: none;
		button.mobile-menu-icon {
			background: none;
			border: none;
			cursor: pointer;
		}
	}
}

@media (max-width: 768px) {
	.NavContainer {
		height: 4.238rem;
		padding: 0 1.25rem 0.063rem 1.25rem;
		div.logo {
			img {
				height: 2.926rem;
				width: 4.922rem;
			}
		}
		.navItems {
			display: none;
			position: relative;

			div.shadow {
				@include flex(column, 1.5rem, start, start);
				position: fixed;
				top: 0;
				width: 70%;
				height: 100vh;
				z-index: 1000;
				background-color: white;
				padding: 4rem 1rem;
				animation: slideRight 0.8s ease-in-out;

				&.itemLeft {
					animation: slideLeft1 0.5s ease-out;
				}
				@keyframes slideLeft1 {
					from {
						left: 0;
					}
					to {
						left: -100%;
						display: none;
					}
				}
				.mobileCrossBtnContainer {
					@include flex("", "", "", "");
					position: absolute;
					top: 0.5rem;
					right: 0.8rem;
					height: 2.188rem;
					border-radius: 10%;
					width: 2.813rem;
					box-shadow: 0 0.25rem 1rem #46464633;
					padding: 0.5rem 0.844rem 0.563rem 0.844rem;
					button.mobile-menu-icon {
						font-size: 1.125rem;
					}
				}

				button.BMPbtn {
					@include fontStyle(1.3rem, "", "");
					position: absolute;
					top: 0.6rem;
					left: 0.5rem;
					margin: 0;
					border: none;
					background-color: transparent;
					box-shadow: none;
					padding: 0;
					width: fit-content;
					text-align: left;
				}
			}

			&.active {
				display: block;
				padding: 0;
				top: 0;
				left: 0;
				position: fixed;
				width: 100%;
				z-index: 10000;
				height: 100vh;
				background-color: rgba(51, 51, 51, 0.5);
				animation: slideRight 0.5s ease-out;
			}
			&.close {
				padding: 0;
				top: 0;
				left: 0;
				position: fixed;
				width: 100%;
				z-index: 10000;
				height: 100vh;
				background-color: rgba(51, 51, 51, 0.5);
				animation: slideLeft 0.8s ease-in-out;
			}

			@keyframes slideRight {
				from {
					left: -100%;
				}
				to {
					left: 0;
				}
			}
			@keyframes slideLeft {
				from {
					display: block;
					left: 0;
				}
				to {
					left: -100%;
					display: none;
				}
			}
		}
		.mobileBtnContainer {
			display: flex;
			height: 2.188rem;
			width: 2.813rem;
			border-radius: 10%;
			box-shadow: 0 0.25rem 1rem #46464633;
			padding: 0.5rem 0.844rem 0.563rem 0.844rem;
			button.mobile-menu-icon {
				font-size: 1.125rem;
			}
		}
	}
}
